import React, { useState, useEffect, useRef } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Box,
  Drawer,
  CssBaseline,
  // Toolbar,
  Typography,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Menu,
  MenuItem,
} from "@mui/material";

import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  Home as HomeIcon,
  FindInPage as FindInPageIcon,
  Create as CreateIcon,
  RecentActors as RecentActorsIcon,
  Key as KeyIcon,
  Logout as LogoutIcon,
  AccountCircle,
  // Upload as UploadIcon,
  // Assessment as AssessmentIcon,
} from "@mui/icons-material";

///////////////// Style Material UI  ////////////////
import Card from "../UI/Card";
import AppBar from "../Style/AppStyle";
import Main from "../Style/MainStyle";
import DrawerHeader from "../Style/DrawHeadStyle";
///////////////// Style Material UI  ////////////////

///////////////// Auth  ////////////////
import { useAuthenticator } from "@aws-amplify/ui-react";
import { GroupMapping } from "../../utils";
import { useSelectedItem } from "../../SelectedContext";
// import LogOut from "../Auth/LogOut";
// import { Auth } from "aws-amplify";
///////////////// Auth  ////////////////

const drawerWidth = 250;

const BaseLayout = ({ children }, ...props) => {
  const [open, setOpen] = useState(false);
  const [account, setAccount] = useState("");
  const [role, setRole] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { signOut } = useAuthenticator();
  const navigate = useNavigate();

  const handleLogout = () => {
    signOut();
    navigate("/");
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleClickOutside = (event) => {
    // Verifica se il Drawer è aperto e se il click è avvenuto fuori dal Drawer
    if (open && !drawerRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  const drawerRef = useRef();

  const stopClickPropagation = (event) => {
    event.stopPropagation();
  };
  const SidebarItems = () => {
    return (
      <>
        <List onClick={handleDrawerClose}>
          <SidebarItem
            ItemIcon={HomeIcon}
            itemKey={"Pagina Iniziale"}
            itemText={"Pagina Iniziale"}
            tooltipTitle={"Pagina Iniziale"}
            linkTo={"/"}
            groups={[]}
          />
          <SidebarItem
            ItemIcon={CreateIcon}
            itemKey={"Crea Campagna"}
            itemText={"Crea Campagna"}
            tooltipTitle={"Crea Campagna"}
            linkTo={"/selezioneCampagna"}
            groups={["admin"]}
          />
          <SidebarItem
            ItemIcon={FindInPageIcon}
            itemKey={"Elenco Campagne"}
            itemText={"Elenco Campagne"}
            tooltipTitle={"Elenco Campagne"}
            linkTo={"/elencoCampagne"}
            groups={["admin", "adminReadOnly", "adminCompany", "editorUser"]}
          />
          <SidebarItem
            ItemIcon={RecentActorsIcon}
            itemKey={"Gestione utenze"}
            itemText={"Gestione utenze"}
            tooltipTitle={"Gestione utenze"}
            linkTo={"/gestioneUtenze"}
            groups={["admin", "adminCompany"]}
          />
        </List>
      </>
    );
  };

  const SidebarItem = ({
    ItemIcon,
    itemKey,
    itemText,
    linkTo,
    groups,
    tooltipTitle,
  }) => {
    const { user } = useAuthenticator((context) => [context.user]);
    const [autorizzato, setAutorizzato] = useState(false);
    const { selected, setSelected } = useSelectedItem();
    // console.log(groups);
    const onLoad = () => {
      if (!user) {
        return;
      }

      const currentAccount = user?.getSignInUserSession()?.getIdToken();
      setAccount(currentAccount?.payload["email"]);
      setRole(
        GroupMapping[
          currentAccount?.payload["cognito:groups"].length === 1
            ? currentAccount?.payload["cognito:groups"][0]
            : currentAccount?.payload["cognito:groups"].filter(
                (role) => role !== "admin"
              )[0]
        ]
      );

      if (groups && groups.length > 0) {
        // console.log(groups);
        let intersection = groups.filter((group) =>
          currentAccount?.payload["cognito:groups"].includes(group)
        );
        // console.log("intersection", intersection);
        if (intersection.length > 0) {
          if (
            (currentAccount?.payload["cognito:groups"].includes(
              "adminReadOnly"
            ) ||
              currentAccount?.payload["cognito:groups"].includes(
                "adminCompany"
              )) &&
            intersection.length === 1
          ) {
            setAutorizzato(false);
          } else setAutorizzato(true);
        } else {
          setAutorizzato(false);
        }
      } else {
        //   // Page not protected for specific group
        setAutorizzato(true);
      }
    };
    useEffect(() => {
      onLoad();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleItemClick = () => {
      setSelected(itemKey);
    };

    return (
      <>
        {autorizzato ? (
          <NavLink to={linkTo} key={itemKey} 
          style={({ isPending }) => {
            return {
              color: isPending ? "" : "#d2d2d2",
              textDecoration: 'none',
              fontSize: '32px'
            };
          }}
          >
            <ListItem
              disablePadding
              disabletouchripple='true'
              sx={{
                backgroundColor: selected === itemKey ? '#d2d2d2' : 'transparent',
                '&:hover': {
                  backgroundColor: '#d2d2d2',
              },
                '&:active': {
                  backgroundColor: '#d2d2d2',
              },
            }}
          >              
          <ListItemButton onClick={handleItemClick} 
          >
                <Tooltip title={tooltipTitle}>
                  <ListItemIcon>{<ItemIcon />}</ListItemIcon>
                </Tooltip>
                <ListItemText secondary={itemText} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ) : null}
      </>
    );
  };

  return (
    <Box sx={{ display: "flex" }} onClick={handleClickOutside}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Box sx={{display: 'flex', justifyContent: "space-between", alignItems:"center", py: 1 }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ ml: 2, /* ...(open && { display: "none" }) */ }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Servizio Campagna Rilevazioni
          </Typography>
          <Box
            sx={{
              mr: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center"
            }}
          >
          <Tooltip title="Account settings">
            <IconButton
              sx={{ mr: 2 }}
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Tooltip>
          <Typography>
            Ciao, {role}
          </Typography>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
          >
            <MenuItem
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <Typography sx={{ mb: 2 }}>
                <strong>Utente:</strong>
                {account}
              </Typography>
              <Typography>
                <strong>Ruolo:</strong>
                {role}
              </Typography>
            </MenuItem>
            <NavLink
              to={"changePassword"}
              style={{ textDecoration: "none", color: "black" }}
              key={"changePassword"}
            >
              <MenuItem>
                <b>Cambia password </b> <KeyIcon />
              </MenuItem>
            </NavLink>
            <MenuItem onClick={handleLogout}>
              <b>Logout </b> <LogoutIcon />
            </MenuItem>
          </Menu>
          {/*<LogOut/>*/}
        </Box>
      </AppBar>
      <Drawer
      ref={drawerRef}
        sx={{
          // width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            zIndex: 1300
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
        onClick={stopClickPropagation}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
            <span style={{ fontSize: 15 }}>Chiudi</span>
          </IconButton>
        </DrawerHeader>
        <Divider />
        <SidebarItems {...props} />
        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        <Card>{children}</Card>
      </Main>
    </Box>
  );
};

export default BaseLayout;
