import React, {useEffect, useState} from "react";
import CampaignService from "../../services/campaign.services";
import Swal from "sweetalert2";
import {Controller, useForm, useWatch} from "react-hook-form";
import {format} from "date-fns";
import {it} from 'date-fns/locale'
import {
    Box, Button,
    Checkbox,
    Chip, Dialog, DialogContent, DialogTitle,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
            width: 250,
            borderRadius: "8px",
        },
    },
};

function UpdateCampaignDialog({
    open,
    setIsOpen,
    selectedValues,
    setNewCampaigns,
    agency_id,
    setLoading
    }) {
    const [projects, setProjects] = useState([]);
    const [feeds, setFeeds] = useState([]);
    const [versions, setVersions] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [isTextFieldFilled, setIsTextFieldFilled] = useState(false);
    const [previouslySelectedFeeds, setPreviouslySelectedFeeds] = useState([]);
    const [schemas, setSchemas] = useState([]);

    


    const getListProjects = () => {
        CampaignService.getProjects().then((data) => {
            setProjects(data);
        });
    };

    const getListFeeds = () => {
        CampaignService.getFeeds().then((data) => {
            setFeeds(data);
        });
    };

    const getVersions = () => {
        CampaignService.getVersions().then((data) => {
            setVersions(data);
        });
    };

    const {
        control,
        handleSubmit,
        formState : {isValid},
        setValue,
        getValues
    } = useForm({ 
        defaultValues: {
            name: selectedValues.name,
            start_date: selectedValues.start_date,
            end_date: selectedValues.end_date,
            is_custom: selectedValues.is_custom,
            select_project: selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.project) || [],
            select_feed: selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.feed) || [],
            select_version: selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.version) || [],
        },
    });


    const startDate = useWatch({ control, name: "start_date" });
    const endDate = useWatch({ control, name: "end_date" });
    const is_custom = useWatch({ control, name: "is_custom" });
    const selected_project = useWatch({ control, name: "select_project" });
    const selected_feed = useWatch({ control, name: "select_feed" });
    const selected_version = useWatch({ control, name: "select_version" });
        
    useEffect(() => {
        setValue('name', selectedValues?.name)
        setValue('start_date', selectedValues?.start_date)
        setValue('end_date', selectedValues?.end_date)
        setValue('is_custom', selectedValues.is_custom)
        setValue('select_project', selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.project))
        const feeds = selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.feed)
        setValue('select_feed', feeds)
        const versions_bis = selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.version)
        setValue('select_version', versions_bis)
        const schema_S = versions?.filter((v) => versions_bis?.includes(v.name) && feeds?.includes(v.feedSourceId.name)).map((v) => {return { version: v.name, schema: v.namespace, feed: v.feedSourceId?.name, project: v.feedSourceId.projectId?.name}});
        setSchemas(schema_S)
    }, [selectedValues?.feed_campaigns, selectedValues?.name, selectedValues?.start_date, selectedValues?.end_date, selectedValues.is_custom ,open, setValue, versions])


    useEffect(() => {
        getListProjects();
        getListFeeds();
        getVersions();
    }, []);

    useEffect(() => {
        // selected_project deve essere SEMPRE un array
        const currentProjects = Array.isArray(selected_project) ? selected_project : [];
    
        const validDefaultVersions = selectedValues?.feed_campaigns?.filter((feed_camp) => 
            currentProjects.includes(feed_camp.project)
        ).map((feed_camp) => feed_camp.version);
    
        setValue('select_version', validDefaultVersions || []);
    }, [selected_project, selectedValues, setValue]);


    const onSubmit = async (data) => {
        console.log("🚀 ~ onSubmit ~ data:", data)
        try {
            document.body.style.pointerEvents = 'none';
            const loaderModal = Swal.fire({
                title: 'Modifica in corso',
                text: "Attendere che la modifica sia ultimata",
                icon: "info",
                showConfirmButton: false,
                //allowOutsideClick: false,
                allowEscapeKey: false,
                //allowEnterKey: false,
                //backdrop: false,
                toast: true,
                didClose: () => {
                    document.body.style.pointerEvents = 'auto';
                }
            });
    
            const { name, start_date, end_date } = data;
            const feedVersions = schemas;
            const startDate = start_date ? format(start_date, 'yyyy-MM-dd') : null;
            const endDate = end_date ? format(end_date, 'yyyy-MM-dd') : null;
            const newCampaign = {
                name: name,
                start_date: startDate,
                end_date: endDate,
                feedVersions: feedVersions,
                is_custom: is_custom,
            };
    
            setIsOpen(false);
    
            const response = await CampaignService.updateCampaign(selectedValues?.id, newCampaign);
    
            loaderModal.close();
    
            if ('error' in response) {
                // Mostra errore
                Swal.fire({
                    title: 'Errore',
                    text: response.error instanceof String ? response.error : 'Impossibile modificare la campagna con i parametri inseriti',
                    icon: 'error',
                    timer: 5000,
                    toast: true,
                    showConfirmButton: false,
                    position: 'center',
                    background: '#F5B7B1',
                });
            } else {
                Swal.fire({
                    title: 'Esito positivo',
                    text: 'Campagna modificata correttamente',
                    icon: 'success',
                    timer: 2500,
                    toast: true,
                    showConfirmButton: false,
                    position: 'center',
                    background: '#DAF7A6',
                }).then(() => {
                    setLoading(true);
                    CampaignService.getAllCampaigns(agency_id).then((res) => {
                        setNewCampaigns(res);
                        setLoading(false);
                    });
                });
            }
        } catch (error) {
            console.error('Errore durante la gestione della richiesta:', error);
        }
    };

    return (
        <Dialog
            open={open}
            fullWidth
            onClose={() => setIsOpen(false)}
            disableEscapeKeyDown={false}
        >
            <DialogTitle> Modifica campagna ordinaria </DialogTitle>
            <DialogContent>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                        display: "grid",
                        alignItems: "baseline",
                        gridRowGap: "20px",
                    }}
                >
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={selectedValues.name}
                        key="name"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                sx={{ display: "grid" }}
                                id="name_campaign"
                                inputProps={{ maxLength: 50 }}
                                label="Nome campagna"
                                variant="standard"
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    setIsTextFieldFilled(e.target.value !== "");
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{ required: "Inserisci un nome" }}
                    />
                    <Controller
                        name="start_date"
                        rules={{ required: "Data obbligatoria" }}
                        control={control}
                        key="start_date"
                        defaultValue={selectedValues.start_date}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                            }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                                <DatePicker
                                    label="Data Inizio"
                                    inputFormat="dd/MM/yyyy"
                                    //disablePast={true}
                                    value={value}
                                    onChange={(newValue) => {
                                        onChange(newValue);
                                        // if (newValue > endDate) {
                                        //     setError("end", {
                                        //         type: "custom",
                                        //         message: "Data non corretta",
                                        //     });
                                        // } else {
                                        //     clearErrors("end");
                                        // }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{ display: "grid" }}
                                            {...params}
                                            inputProps={{ ...params.inputProps, readOnly: true }} // evitare l'inserimento dattiloscritto della data
                                            id="start_date"
                                            variant="standard"
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )
                        }
                    />
                    <Controller
                        name="end_date"
                        rules={{ required: "Data obbligatoria" }}
                        control={control}
                        key="end_date"
                        defaultValue={selectedValues.end_date}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                                <DatePicker
                                    label="Data Fine"
                                    inputFormat="dd/MM/yyyy"
                                    //disablePast={true}
                                    minDate={startDate}
                                    value={value}
                                    onChange={(newValue) => {
                                        onChange(newValue);
                                        // if (newValue > endDate) {
                                        //     setError("end", {
                                        //         type: "custom",
                                        //         message: "Data non corretta",
                                        //     });
                                        // } else {
                                        //     clearErrors("end");
                                        // }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{ display: "grid" }}
                                            {...params}
                                            inputProps={{ ...params.inputProps, readOnly: true }} // evitare l'inserimento dattiloscritto della data
                                            id="end_date"
                                            variant="standard"
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )
                        }
                    />
                    <Controller
                        name="select_project"
                        control={control}
                        key="select_project"
                        defaultValue={selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.project)}
                        rules={{ required: "Selezione obbligatoria" }}
                        render={({ field: { onChange, value } }) => (
                            <FormControl sx={{ display: "grid"}}>
                                <InputLabel
                                    id="mutiple-select-project"
                                    sx={{ display: "grid" }}
                                >
                                    Seleziona Progetto
                                </InputLabel>
                                <Select
                                    labelId="mutiple-select-project"
                                    multiple
                                    variant="standard"
                                    value={Array.isArray(value) ? value : []}
                                    onChange={(event) => {
                                        const project_S = event.target.value || [];
                                        const currentProjects = Array.isArray(project_S) ? project_S : [];
                                    
                                        // filtro i feed validi per i progetti attualmente selezionati
                                        const validFeedsForSelectedProjects = feeds
                                            .filter(feed => currentProjects.includes(feed.projectId.name))
                                            .map(feed => feed.name);
                                    
                                        // recupero i feed attualmente selezionati dal form
                                        const currentlySelectedFeeds = getValues('select_feed');
                                    
                                        // definisco quali feed e versioni sono associati ai progetti reinseriti
                                        const restoredFeedsAndVersions = selectedValues?.feed_campaigns?.filter(fc => 
                                            project_S.includes(fc.project) && !currentlySelectedFeeds.includes(fc.feed)
                                        );
                                    
                                        // combino i feed attualmente selezionati con quelli ripristinati
                                        const updatedSelectedFeeds = [...new Set([
                                            ...currentlySelectedFeeds, 
                                            ...restoredFeedsAndVersions.map(fc => fc.feed)
                                        ].filter(feed => validFeedsForSelectedProjects.includes(feed)))];
                                    
                                        setValue('select_feed', updatedSelectedFeeds);
                                    
                                        // combino le versioni attualmente selezionate con quelle ripristinate
                                        const updatedSelectedVersions = [...new Set([
                                            ...getValues('select_version'), 
                                            ...restoredFeedsAndVersions.map(fc => fc.version)
                                        ])];
                                    
                                        setValue('select_version', updatedSelectedVersions);
                                    
                                        onChange(project_S);
                                    }}
                                    renderValue={(selected) => {
                                        for (let i = 0; i < selected.length; i++){
                                            if(selected[i] !== undefined)
                                                break
                                            else
                                                selected[i] = selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.project)[i]
                                        }
                                        return(
                                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>)
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {/* <MenuItem value="_all">
                                        <ListItemIcon>
                                            <Checkbox checked={Array.isArray(value) && value.length === projects.length} />
                                        </ListItemIcon>
                                        <ListItemText primary="Seleziona tutto" />
                                    </MenuItem> */}
                                    {projects.map((option) => (
                                        <MenuItem key={option._id} value={option.name}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    value={option.name}
                                                    checked={Array.isArray(value) && value.includes(option.name)}
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary={option.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="select_feed"
                        control={control}
                        defaultValue={selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.feed)}
                        rules={{ required: "Selezione obbligatoria" }}
                        render={({ field: { onChange, value } }) => (
                            <FormControl sx={{ display: "grid" }}>
                                <InputLabel id="mutiple-select-feed" sx={{ display: "grid" }}>
                                    Seleziona Feed
                                </InputLabel>
                                <Select
                                    labelId="mutiple-select-feed"
                                    multiple
                                    variant="standard"
                                    value={Array.isArray(value) ? value : []}
                                    onChange={(event) => {
                                        let feed_S = event.target.value;
                                        setPreviouslySelectedFeeds(feed_S);
                                    
                                        // recupero le versioni attualmente selezionate dal form
                                        const currentlySelectedVersions = getValues('select_version');
                                    
                                        // filtro le versioni basandomi sui feed selezionati
                                        const validVersionsForSelectedFeeds = versions
                                            .filter(version => feed_S.includes(version.feedSourceId?.name))
                                            .map(version => version.name);
                                    
                                        // determino quali versioni dovrebbero essere ripristinate in base ai feed reinseriti
                                        const restoredVersions = selectedValues?.feed_campaigns
                                            ?.filter(fc => feed_S.includes(fc.feed) && !currentlySelectedVersions.includes(fc.version))
                                            .map(fc => fc.version) || [];
                                    
                                        const updatedSelectedVersions = [...new Set([...currentlySelectedVersions, ...restoredVersions])]
                                            .filter(versionName => validVersionsForSelectedFeeds.includes(versionName));
                                    
                                        setValue('select_version', updatedSelectedVersions);
                                    
                                        const schema_S = versions.filter(v => 
                                            updatedSelectedVersions.includes(v.name)
                                        ).map(v => ({
                                            version: v.name,
                                            schema: v.namespace, 
                                            feed: v.feedSourceId?.name, 
                                            project: v.feedSourceId.projectId?.name
                                        }));
                                    
                                        setSchemas(schema_S);
                                    
                                        return onChange(feed_S);
                                    }}
                                    
                                                                                                            
                                    renderValue={(selected) => {
                                        for (let i = 0; i < selected.length; i++){
                                            if(selected[i] !== undefined)
                                                break
                                            else
                                                selected[i] = selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.feed)[i]
                                        }
                                        return(
                                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>)
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {feeds?.filter((feed) =>
                                            Array.isArray(selected_version) && selected_project?.includes(feed.projectId.name))
                                        .map((option) => (
                                            <MenuItem key={option.name} value={option.name}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        value={option.name}
                                                        checked={Array.isArray(value) && value.includes(option.name)}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText
                                                    primary={option.name}
                                                    secondary={option.projectId.name}
                                                />
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="select_version"
                        defaultValue={selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.version)}
                        control={control}
                        rules={{ required: "Selezione obbligatoria" }}
                        render={({ field: { onChange, value } }) => (
                            <FormControl sx={{ display: "grid" }}>
                                <InputLabel id="mutiple-select-version" sx={{ display: "grid" }}>
                                    Seleziona Versione
                                </InputLabel>
                                <Select
                                    labelId="mutiple-select-version"
                                    variant="standard"
                                    multiple
                                    value={value || ""}
                                    onChange={(event) => {
                                        const version_S = event.target.value;
                                        const lastSelectedVersion = version_S[version_S.length - 1];
                                        
                                        const updatedSelectedVersions = version_S.filter(selectedVersion => {
                                            const versionDetail = versions.find(v => v.name === selectedVersion);
                                            const lastVersionDetail = versions.find(v => v.name === lastSelectedVersion);
                                            
                                            return versionDetail.feedSourceId.name !== lastVersionDetail.feedSourceId.name || selectedVersion === lastSelectedVersion;
                                        });

                                        const schema_S = versions.filter(v => updatedSelectedVersions.includes(v.name)).map(v => ({
                                            version: v.name,
                                            schema: v.namespace,
                                            feed: v.feedSourceId?.name,
                                            project: v.feedSourceId.projectId?.name
                                        }));

                                        setSchemas(schema_S);
                                        return onChange(updatedSelectedVersions);
                                    }}
                                    renderValue={(selected) => (
                                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                            {selected.map((value, index) => (
                                                <Chip key={`${value}_${index}`} label={value || selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.version)[index]} />
                                            ))}
                                        </Box>
                                    )}
                                    MenuProps={MenuProps}
                                >
                                    {versions
                                        .filter(version => Array.isArray(selected_feed) && selected_feed.includes(version.feedSourceId?.name))
                                        .map((option) => (
                                            <MenuItem key={option.name} value={option.name}>
                                                <ListItemText primary={option.name} secondary={option.feedSourceId?.name} />
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        )}
                    />


                    <Controller
                        name="is_custom"
                    control={control}
                    defaultValue={selectedValues?.is_custom}
                    render={({ field: { onChange, value } }) => (
                        <div style={{display: 'none'}}>
                            <Checkbox
                                checked={value}
                                disabled
                            />
                        </div>
                        )}
                    />
                    <br />
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <Box sx={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => setIsOpen(false)}
                            >
                                Annulla modifiche
                            </Button>
                            
                            <Button
                                type="submit"
                                variant="outlined"
                                disabled={!schemas.length || startDate > endDate || !isValid}
                            >
                                Applica modifiche
                            </Button>
                        </Box>
                        
                        {startDate > endDate && (
                            <Typography color="error" sx={{ mt: 2, textAlign: 'right' }}>
                            Verificare le date
                            </Typography>
                        )}
                        </Box>                

                </form>
        </DialogContent>
        </Dialog>
    );
}

export default UpdateCampaignDialog;