import { useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { useNavigate } from 'react-router-dom';
import Token from '../../token-utils'


const useSession = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const navigate = useNavigate();

  //////SESSION INFINITE
//   useEffect(() => {
//     const refreshSessionToken = () => {
//       const currentToken = user?.getSignInUserSession()?.getAccessToken().getJwtToken();

//       if (!currentToken) {
//         navigate('/session-expired', { replace: true });
//       } else {
//         Token.setToken(currentToken);
//       }
//     };

//     refreshSessionToken();

//     const intervalId = setInterval(refreshSessionToken, 3000);

//     return () => clearInterval(intervalId);
//   }, [user, navigate]);

// };


    //// SESSION 30 MINUTI CON REINDIRIZZAMENTO
useEffect(() => {
    const sessionTimeoutId = setTimeout(() => {
        navigate('/session-expired', { replace: true });
      }, 1800000); // 30 minuti di sessione

  return () => clearTimeout(sessionTimeoutId);
}, [navigate]);

const currentToken = user?.getSignInUserSession()?.getAccessToken().getJwtToken();
if (currentToken) {
  Token.setToken(currentToken);
}
};

export default useSession;