import React from "react";
import { Box, Typography } from "@mui/material";
import ListCampaigns from "../UI/ListCampaigns";

function SearchCampaigns() {
  return (
    <Box>
      <Typography
        color="text.secondary"
        gutterBottom
        component={"span"}
        align="center"
      >
        <h2> Elenco Campagne</h2>
      </Typography>

      <Box >
        <ListCampaigns/>
      </Box>
    </Box>

  );
}

export default SearchCampaigns;
