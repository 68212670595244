import 'animate.css';
// import { Auth } from 'aws-amplify';
import updateHeaders from "../utils"

class RilevazioniService {

  getDetections = (agency_id, campaign_id) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/recordings/?agency_id=" + agency_id + "&campaign_id=" + campaign_id;
    return fetch(fetchUrl, updateHeaders()).then((res) => res.json()).then((json) => {return json});
  };

  getDetection = (agency_id, campaign_id, recording_id) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/recordings/" + recording_id + "?agency_id=" + agency_id + "&campaign_id=" + campaign_id;
    return fetch(fetchUrl, updateHeaders()).then((res) => res.json()).then((json) => {return  json});
  };

  getLicensePlates = (licensePlates) => {
    const valueApiKey = process.env.REACT_APP_KEY_VALUE
    return fetch(`https://kj62z3aauflu66vhzfwqd4t2740qsism.lambda-url.eu-central-1.on.aws/targhe?targa_starts_with=${licensePlates}` , {
      method: "GET",
      headers: {
        "x-api-key": valueApiKey
      },
      // // mode: 'no-cors'
    })
    .then(response => {
      return response.json();
    })
    .then(data => data)
    .catch(error => {
      console.error('Errore durante il fetch delle targhe:', error)
    })
  }

  printDetections = (recording_id, agency_id, campaign_id) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/recordings/print/" + recording_id + "?agency_id=" + agency_id + "&campaign_id=" + campaign_id;
    return fetch(fetchUrl, updateHeaders()).then((response) => response.blob())
        .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
          var _url = window.URL.createObjectURL(blob);
          window.open(_url, "_blank").focus(); // window.open + focus
        }).catch((err) => {
          console.log(err);
        });
  };


  uploadDetections = (file,campaign_id) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/recording-details/uploadFile/" + campaign_id;
    let formData = new FormData();

    formData.append('aFile', file);


    return fetch(fetchUrl, updateHeaders({
      method: 'POST',
      body: formData,
    }))
      .then((res) => res.json())
      .catch((err) => console.error(err));
  };


  createDetection = (data) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/recording-details/upload";
    return fetch(fetchUrl, updateHeaders({
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }))
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          return res.json().then((errorData) => {
            throw new Error(
              errorData.messages || "Si è verificato un errore, ci scusiamo"
            );
          });
        }
      })
      .catch((err) => {
        console.error(err);
        throw err;
      });
  };
  }
  // eslint-disable-next-line import/no-anonymous-default-export
export default new RilevazioniService();
