import Token from "./token-utils";

export default function updateHeaders(options) {
    // console.log("token",Token.getToken())
    const update = { ...options };
    update.headers = {
        ...update.headers,
        accesstoken: Token.getToken(),
        
    };
    return update;
}

export const GroupMapping = {
    admin: 'SuperAdmin',
    adminCompany: 'AdminAzienda',
    adminReadOnly: 'AdminSolaLettura',
    editorUser: 'EditorUser'
}