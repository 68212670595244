import React, { useEffect, useState } from "react";
import {
  itIT,
  GridActionsCellItem,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import RilevazioniService from "../../services/rilevazioni.services";
import "../Style/List.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useParams } from "react-router-dom";
import {Chip, Tooltip, Fade, Box, Grid} from "@mui/material";
import { useNavigate } from "react-router-dom";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import SendIcon from "@mui/icons-material/Send";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import UndoIcon from '@mui/icons-material/Undo';
import DownloadIcon from '@mui/icons-material/Download';
import PrintIcon from "@mui/icons-material/Print";
import StripedDataGrid from "../Style/StripedDataGrid";
import Swal from "sweetalert2";
import CampaignServices from "../../services/campaign.services";

const ListRecordings = (props) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [isLoading, setIsLoading] = useState(true);
  const [detections, setDetections] = useState([]);
  const [visible, setVisible] = useState(true);
  const [atomic, setAtomic] = useState(false);
  const [file, setFile] = useState({});
  const fileInput = React.useRef();
  const [pageSize, setPageSize] = useState(15);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    actions: true,
  });
  const [sortModel, setSortModel] = useState([
    {
      field: 'id_rilevazione',
      sort: 'asc',
    }
  ]);

  const navigate = useNavigate();
  let agency_id = user.attributes["custom:tpl_id"];
  let { campaign_id } = useParams();

  useEffect(() => {
    checkUserRole();
    getRilev(agency_id, campaign_id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const checkUserRole = () => {
    if (!user) return;
    const currentAccount = user?.getSignInUserSession()?.getAccessToken()
      ?.payload["cognito:groups"];
    if (currentAccount.includes("admin")) {
      setVisible(true);
      setAtomic(true);
      setColumnVisibilityModel({ ...columnVisibilityModel, actions: true });
    }
    if (currentAccount.includes("adminReadOnly")) {
      setVisible(false);
      setColumnVisibilityModel({ ...columnVisibilityModel, actions: false });
    }
  };

  const getRilev = (agency_id, campaign_id) => {
    RilevazioniService.getDetections(agency_id, campaign_id).then((data) => {
      setDetections(data);
      setIsLoading(false);
      console.log(data);
    });
  };


  function sendCSV(file, campaign_id) {
    setIsLoading(true);
    RilevazioniService.uploadDetections(file, campaign_id).then((data) => {
      setIsLoading(false);
      setFile({})
      if (data.success)
        Swal.fire({
          icon: "success",
          title: "Caricamento eseguito",
          text: "Il file è stato caricato correttamente",
          toast: true,
        })
      else
        Swal.fire({
          icon: "error",
          title: "Errore",
          text: data.message.join('; '),
          toast: true,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        })

    })
  }
  function uploadCSV(e) {
    setFile(e.target.files[0]);
  }

  const printScheda = (recording_id, agency_id, campaign_id) => {
    RilevazioniService.printDetections(recording_id, agency_id, campaign_id);
  };

  ////////////////////////////////////////////////////

  const goToDetection = (recording_id) => {
    navigate("/caricaRilevazione/" + recording_id);
  };

  const downloadCsv = (campaign_id) => {
    CampaignServices.downloadCsv(campaign_id, props.campaignName,agency_id)
  }

  const downloadAtomic = (campaign_id) => {
    CampaignServices.downloadAtomic(campaign_id, props.campaignName,agency_id)
  }

  const columns = [
    {
      field: "id_rilevazione",
      headerName: "ID Rilevazione",
      flex: 0.5,
    },
    {
      field: "trip_id",
      headerName: "Trip_ID",
      flex: 1,
    },
    {
      field: "agency_name",
      headerName: "Azienda",
      flex: 1,
    },
    {
      field: "linea",
      headerName: "Linea",
      flex: 1.2,
    },
    {
      field: "percorso",
      headerName: "Percorso",
      flex: 1
    },
    {
      field: "percorso_da",
      headerName: "Percorso da:",
      flex: 1,
    },
    {
      field: "percorso_a",
      headerName: "Percorso a:",
      flex: 1,
    },
    {
      field: "feed",
      headerName: "Feed",
      flex: 1,
    },
    {
      field: "corsa",
      headerName: "Corsa",
      flex: 0.8,
    },
    {
      field: "frequenza",
      headerName: "Frequenza",
      flex: 1,
    },
    {
      field: "rilevazione",
      headerName: "N. Rilevazione",
      flex: 0.7,
    },
    {
      field: "completed",
      headerName: "Compilata",
      flex: 0.7,
      type: "boolean",
    },
    {
      field: "actions",
      headerName: "",
      type: "actions",
      flex: 0.5,
      filterable: false,
      hideable: visible,
      getActions: ({ id, row }) => {
        return (visible ?
          [
            <GridActionsCellItem
              label="Open_details"
              onClick={() => goToDetection(id)}
              transitioncomponent={Fade}
              transitionprops={{ timeout: 800 }}
              followcursor="true"
              sx={{ fontSize: 20 }}
              color="inherit"
              disabled={row.CSV}
              icon={
                <Tooltip
                  title={
                    <span style={{ margin: 1, fontSize: 13 }}>Apri scheda</span>
                  }
                >
                  <FileOpenIcon />
                </Tooltip>
              }
            />,
            <GridActionsCellItem
              color="inherit"
              label="Print"
              onClick={() =>
                printScheda(id, agency_id, campaign_id)
              }
              transitioncomponent={Fade}
              transitionprops={{ timeout: 800 }}
              followcursor="true"
              disabled={row.CSV}
              sx={{ fontSize: 20 }}
              icon={
                <Tooltip
                  title={
                    <span style={{ margin: 1, fontSize: 13 }}>
                      Stampa scheda
                    </span>
                  }
                >
                  <PrintIcon />
                </Tooltip>
              }
            />,
          ] : [<GridActionsCellItem
                    label="Open_details"
                    onClick={() => goToDetection(id)}
                    transitioncomponent={Fade}
                    transitionprops={{ timeout: 800 }}
                    followcursor="true"
                    sx={{ fontSize: 20 }}
                    color="inherit"
                    icon={
                      <Tooltip
                          title={
                            <span style={{ margin: 1, fontSize: 13 }}>Apri scheda</span>
                          }
                      >
                        <FileOpenIcon />
                      </Tooltip>
                    }
                />]
        );
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }
  return (
    <>
    <Grid container spacing={2} alignItems="center" justifyContent="space-between">
      <Grid item>
        <Button variant="outlined" onClick={() => navigate(-1)} startIcon={<UndoIcon />}>
          Torna ad elenco campagne
        </Button>
      </Grid>

      <Grid item xs={12} md="auto">
        <Box sx={{ flexGrow: 1 }} />
      </Grid>

      <Grid item>
        {atomic && (
          <Stack direction="row" spacing={2} alignItems="center">
            <Button
              variant="contained"
              color="success"
              endIcon={<DownloadIcon />}
              onClick={() => downloadCsv(campaign_id)}
            >
              CSV Load factor
            </Button>

            { atomic && <Button
              variant="contained"
              color="success"
              endIcon={<DownloadIcon />}
              onClick={() => downloadAtomic(campaign_id)}
            >
              CSV Dato atomico
            </Button> }

            {file?.name ? (
              <>
                <Chip label={file?.name} onDelete={() => setFile({})} variant="filled" clickable />
                <Button variant="contained" endIcon={<SendIcon />} onClick={() => sendCSV(file, campaign_id)}>
                  Invia file
                </Button>
              </>
            ) : (
              <>
                <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={uploadCSV} />
              {visible && <Button
                  variant="contained"
                  endIcon={<FileUploadIcon />}
                  onClick={() => fileInput.current.click()}
                >
                  Carica file CSV
                </Button>}
              </>
            )}
          </Stack>
        )}
      </Grid>

    </Grid>
      <br />
      <div style={{ height: '70vh', width: "100%" }}>
        <StripedDataGrid
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[15, 25, 35, 45]}
          pagination
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={detections}
          columns={columns}
          loading={isLoading}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          hideFooterSelectedRowCount
          getEstimatedRowHeight={() => 100}
          getRowId={(row) => row.id_rilevazione}
          getRowHeight={() => "auto"}
          slots={{ toolbar: CustomToolbar }}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: 1,
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: 2,
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: 3,
            },
          }}
          initialState={{
            columns: {
              columnVisibilityModel: {
                ...columnVisibilityModel,
                trip_id: false,
                percorso_da: false,
                percorso_a: false,
              },
            },
          }}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
        />
      </div>
    </>
  );
};

export default ListRecordings;
