import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Typography } from "@mui/material";
import "./index.css";
import App from "./App";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import "./index.css";
import { Authenticator, View, Image, useTheme } from "@aws-amplify/ui-react";

import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";
import LogoRegioneAMP_color from "../src/Img/LogoRegioneAMP_color.png";
Amplify.configure(awsExports);

I18n.putVocabularies(translations);
I18n.setLanguage("it");

I18n.putVocabularies({
    it: {
        "We Texted You": "Ti abbiamo inviato una email"

    }
})
const components = {
  Header() {
    const { tokens } = useTheme();

    return (
      <>
        <Typography
          sx={{ display: "flex", justifyContent: "center", marginTop: "20vh" }}
          color="text.secondary"
          component={"div"}
          align="center"
        >
          <h1> Servizio Saliti e Discesi</h1>
        </Typography>
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="RegioneAMP logo" src={LogoRegioneAMP_color} />
        </View>
      </>
    );
  },
};
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <BrowserRouter>
      <Authenticator hideSignUp={true} components={components}>
        <App />
      </Authenticator>
    </BrowserRouter>
  </>
);
