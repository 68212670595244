import React from "react";
import { useLocation, useParams } from "react-router-dom";

const mapPathname = (pathname) => {
  switch (pathname) {
    case "selezioneCampagna":
      return "Seleziona campagna";
    case "creaCampagnaOrdinaria":
      return "Creazione campagna ordinaria";
    case "creaCampagnaCustom":
      return "Creazione campagna custom";
    case "configuraCampagnaCustom":
      return "Configura campagna custom:";
    case "elencoCampagne":
      return "Elenco campagne";
    case "listaRilevazioni":
      return "Lista rilevazioni ";
    case "caricaRilevazione":
      return "Carica rilevazione n.";
    case "modificaRilevazione":
      return "Modifica rilevazione";
    case "gestioneUtenze":
      return "Gestione utenze";
    case "changePassword":
      return "Cambio password";
    default:
      return pathname;
  }
};

const Breadcrumb = () => {
  const location = useLocation();
  let pathnames = location.pathname.split("/").filter((x) => x);
  const params = useParams();

  const isListaRilevazioni = pathnames.includes("listaRilevazioni");

  if (isListaRilevazioni) {
    pathnames = pathnames.slice(0, -1);
  }


  return (
    <div style={{ display: "flex", marginBottom: '16px' }}>
      {location.pathname === "/" ? null : <span>Home</span>}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join("/")}`;

        // Aggiungi i parametri dinamici all'URL del breadcrumb
        const breadcrumbWithParams = to + Object.keys(params).map(param => `/${params[param]}`).join("");
        const mappedValue = mapPathname(value);

        return (
          <div key={index}>
            {last ? (
              <span key={breadcrumbWithParams}>&nbsp;&bull; {mappedValue}</span>
            ) : (
              <span key={breadcrumbWithParams}>
                &nbsp;&bull; <span to={breadcrumbWithParams}>{mappedValue}</span>
              </span>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
