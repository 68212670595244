import React, {useEffect, useState} from "react";
import CampaignService from "../../services/campaign.services";
// import Swal from "sweetalert2";
import {Controller, useForm, useWatch} from "react-hook-form";
import {format} from "date-fns";
import {it} from 'date-fns/locale'
import {
    Box, Button,
    Checkbox,
    Chip, Dialog, DialogContent, DialogTitle,
    Divider,
    FormControl,
    Grid,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Skeleton,
    TextField,
    Typography
} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
            width: 250,
            borderRadius: "8px",
        },
    },
};

function UpdateCampaignDialog({
    open,
    setIsOpen,
    selectedValues,
    // setNewCampaigns,
    // agency_id,
    // setLoading
    }) {
    const [projects, setProjects] = useState([]);
    const [feeds, setFeeds] = useState([]);
    const [versions, setVersions] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [isTextFieldFilled, setIsTextFieldFilled] = useState(false);
    const [schemas, setSchemas] = useState([]);
    const [trips, setTrips] = useState([]);
    const [stroke, setStroke] = useState([]);
    const [filteredStrokes, setFilteredStrokes] = useState([]);
    const [selezionati, setSelezionati] = useState([]);
    const [selectedCompleteStroke, setSelectedCompleteStroke] = useState([]);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    const arraysEqual = (a, b) => {
        if (a.length !== b.length) return false;
        const sortedA = [...a].sort();
        const sortedB = [...b].sort();
        return sortedA.every((val, index) => val === sortedB[index]);
    }
    


    const getListProjects = () => {
        CampaignService.getProjects().then((data) => {
            setProjects(data);
        });
    };

    const getListFeeds = () => {
        CampaignService.getFeeds().then((data) => {
            setFeeds(data);
        });
    };

    const getVersions = () => {
        CampaignService.getVersions().then((data) => {
            setVersions(data);
        });
    };
    
    const getSelectedTripsAndStrokes = async (version, campaignId) => {
        const data = await CampaignService.getTripsandStroke(version, campaignId);
        setSelezionati(data);
    };

    const getListTrips = (version) => {
        CampaignService.getTrips(version).then((data) => {
          setTrips(data);
        });
      };

      const getListStokes = (version) => {
        CampaignService.getStroke(version).then((data) => {
          setStroke(data);
        })
      }

      // Funzione per ottenere i trips selezionati
    function getSelectedTrips(selezionati) {
        return selezionati.filter(item => item.selected).map(item => item.route_id);
}

    // Funzione per ottenere gli stroke selezionati
    function getSelectedStrokes(selezionati) {
        return selezionati.flatMap(item => item.trips).filter(trip => trip.selected).map(trip => trip.trip_id);
    }


    const {
        control,
        handleSubmit,
        formState : {isValid},
        setValue,
        getValues
    } = useForm({ 
        defaultValues: {
            name: selectedValues.name,
            start_date: selectedValues.start_date,
            end_date: selectedValues.end_date,
            is_custom: selectedValues.is_custom,
            select_project: selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.project) || [],
            select_feed: selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.feed) || [],
            select_version: selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.version) || [],
            select_trips: getSelectedTrips(selezionati) || [],
            select_stroke: getSelectedStrokes(selezionati) || [],
        },
    });

    // eslint-disable-next-line no-unused-vars
    const startDate = useWatch({ control, name: "start_date" });
    const endDate = useWatch({ control, name: "end_date" });
    const is_custom = useWatch({ control, name: "is_custom" });
    const selected_project = useWatch({ control, name: "select_project" });
    const selected_feed = useWatch({ control, name: "select_feed" });
    // eslint-disable-next-line no-unused-vars
    const selected_version = useWatch({ control, name: "select_version" });
    const selected_trips = useWatch({ control, name: "select_trips" });
    const selected_stroke = useWatch({control, name: 'select_stroke'})
        
    useEffect(() => {
        setValue('name', selectedValues?.name)
        setValue('start_date', selectedValues?.start_date)
        setValue('end_date', selectedValues?.end_date)
        setValue('is_custom', selectedValues.is_custom)
        setValue('select_project', selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.project))
        const feeds = selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.feed)
        setValue('select_feed', feeds)
        const versions_bis = selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.version)
        setValue('select_version', versions_bis)
        const schema_S = versions?.filter((v) => 
        versions_bis?.includes(v.name) && feeds?.includes(v.feedSourceId.name)).map((v) => 
        {return { 
            version: v.name, 
            schema: v.namespace, 
            feed: v.feedSourceId?.name, 
            project: v.feedSourceId.projectId?.name
        }});
        setSchemas(schema_S)
    }, [selectedValues?.feed_campaigns, selectedValues?.name, selectedValues?.start_date, selectedValues?.end_date, selectedValues.is_custom ,open, setValue, versions])
    
    useEffect(() => {
        if (selezionati && selezionati.length > 0) {
        setValue('select_trips', getSelectedTrips(selezionati));    
        setValue('select_stroke', getSelectedStrokes(selezionati));
        }
    }, [selezionati, setValue]);

    const [initialValues, setInitialValues] = useState({});
    useEffect(() => {
        if (selectedValues && selezionati) { // sono presenti dati?
          setInitialValues({
            project: selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.project) || [],
            feed: selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.feed) || [],
            version: selectedValues?.feed_campaigns?.map((feed_camp) => feed_camp.version) || [],
            trips: getSelectedTrips(selezionati) || [],
            stroke: getSelectedStrokes(selezionati) || [],
          });
        }
      }, [selectedValues, selezionati]);
      
        
    useEffect(() => {
        getListProjects();
        getListFeeds();
        getVersions();
    }, []);


    // CASISTICA: potrebbero essere state selezionate n linee e corse e ci vorrà tempo per caricarle
    // e i select resterebbero vuoti
    useEffect(() => {
        const campaign = selectedValues?.feed_campaigns?.[0]?.campaign_id;
        if (schemas.length > 0 && campaign) {
            setLoading(true);
            getSelectedTripsAndStrokes(schemas[0].schema, campaign)
                .then(() => {
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        }
    }, [schemas, selectedValues?.feed_campaigns]);

    useEffect(() => {
        if (schemas.length > 0 ) {
            getListTrips(schemas[0].schema);
            getListStokes(schemas[0].schema);
          }
    }, [schemas]);

    useEffect(() => {
        if (selected_trips && selected_trips.length > 0) {        
        const updatedFilteredStrokes = stroke.filter(strokeItem => 
          selected_trips.includes(strokeItem.route.route_id)
        );
        setFilteredStrokes(updatedFilteredStrokes);
        // ottiengo il valore attuale di select_stroke dal form
        const currentStrokeValues = getValues('select_stroke');
        
        // filtro gli stroke che non appartengono più alle trips selezionate
        const newStrokeValues = currentStrokeValues.filter(strokeId => 
            updatedFilteredStrokes.some(strokeItem => strokeItem.trip_id === strokeId)
            );
            
            // aggiorno il valore di select_stroke nel form se è cambiato
            if (newStrokeValues.length !== currentStrokeValues.length || 
                !newStrokeValues.every(val => currentStrokeValues.includes(val))) {
                    setValue('select_stroke', newStrokeValues);
                }
            }
      }, [selected_trips, stroke, setValue, getValues]);

    useEffect(() => {
        if (selezionati && selezionati.length > 0) {
          // unisco gli array trips in uno
          const allTrips = selezionati.reduce((acc, cur) => {
            if (cur.trips && cur.selected) { // 'cur' deve avere la proprietà trips e selected
              return acc.concat(cur.trips);
            }
            return acc;
          }, []);
      
          const updatedSelectedCompleteStroke = allTrips
            .filter(trip => trip.selected) // filtro solo i viaggi selected
            .map(trip => ({
              trip_id: trip.trip_id, // prendo l'ID del viaggio e il num di gg proposti
              num_giorni_proposti: trip.num_giorni_proposti
            }));
      
          setSelectedCompleteStroke(updatedSelectedCompleteStroke);
        }
      }, [selezionati]);

  

    const onSubmit = (data) => {
        const { name, start_date, end_date } = data;
        const feedVersions = schemas;
        const startDate = start_date ? format(start_date,"yyyy-MM-dd") : null;
        const endDate = end_date ? format(end_date,"yyyy-MM-dd") : null;
        const newCampaign = {
          name,
          start_date: startDate,
          end_date: endDate,
          feedVersions,
          is_custom: is_custom,
        };
        console.log('new custom campaign', newCampaign)

        navigate('/configuraCampagnaCustom/'+feedVersions[0].schema,{
          state:{
                name: name,
                start_date:startDate,
                end_date:endDate,
                project:feedVersions[0].project,
                feed:feedVersions[0].feed,
                version:feedVersions[0].version,
                tripsIds: selected_stroke,
                selectedCompleteStroke: selectedCompleteStroke,
                campaignId: selectedValues?.feed_campaigns?.[0]?.campaign_id
              }
          }
        )
      };

    return (
        <Dialog
            open={open}
            fullWidth
            maxWidth="lg"
            onClose={() => setIsOpen(false)}
            disableEscapeKeyDown={false}
        >
            <DialogTitle> Modifica campagna custom</DialogTitle>
            <DialogContent>
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    style={{
                        display: "grid",
                        alignItems: "baseline",
                        gridRowGap: "20px",
                    }}
                >
            <Grid container spacing={1} sx={{ mt: 6, mb: 2, justifyContent: "center" }}>
                <Grid item xs={12} sm={3}>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={selectedValues.name}
                        key="name"
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                                sx={{ display: "grid", mt: 1, mb: 3 }}
                                id="name_campaign"
                                inputProps={{ maxLength: 50 }}
                                label="Nome campagna"
                                variant="standard"
                                value={value}
                                onChange={(e) => {
                                    onChange(e);
                                    setIsTextFieldFilled(e.target.value !== "");
                                }}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={{ required: "Inserisci un nome" }}
                    />

                    <Controller
                        name="start_date"
                        rules={{ required: "Data obbligatoria" }}
                        control={control}
                        key="start_date"
                        defaultValue={selectedValues.start_date}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                            }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                                <DatePicker
                                    label="Data Inizio"
                                    inputFormat="dd/MM/yyyy"
                                    //disablePast={true}
                                    value={value}
                                    onChange={(newValue) => {
                                        onChange(newValue);
                                        // if (newValue > endDate) {
                                        //     setError("end", {
                                        //         type: "custom",
                                        //         message: "Data non corretta",
                                        //     });
                                        // } else {
                                        //     clearErrors("end");
                                        // }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{ display: "grid", mb: 3 }}
                                            {...params}
                                            inputProps={{ ...params.inputProps, readOnly: true }} // evitare l'inserimento dattiloscritto della data
                                            id="start_date"
                                            variant="standard"
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )
                        }
                    />

                    <Controller
                        name="end_date"
                        rules={{ required: "Data obbligatoria" }}
                        control={control}
                        key="end_date"
                        defaultValue={selectedValues.end_date}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                                <DatePicker
                                    label="Data Fine"
                                    inputFormat="dd/MM/yyyy"
                                    //disablePast={true}
                                    value={value}
                                    onChange={(newValue) => {
                                        onChange(newValue);
                                        // if (newValue > endDate) {
                                        //     setError("end", {
                                        //         type: "custom",
                                        //         message: "Data non corretta",
                                        //     });
                                        // } else {
                                        //     clearErrors("end");
                                        // }
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            sx={{ display: "grid", mb: 2, mt:2 }}
                                            {...params}
                                            inputProps={{ ...params.inputProps, readOnly: true }} // evitare l'inserimento dattiloscritto della data
                                            id="end_date"
                                            variant="standard"
                                            error={!!error}
                                            helperText={error ? error.message : null}
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        )
                        }
                    />

                    <Controller
                        name="select_project"
                        control={control}
                        rules={{ required: "Selezione obbligatoria" }}
                        render={({ field: { onChange, value } }) => (
                            <FormControl sx={{ display: "grid" }}>
                            <InputLabel id="select-project" sx={{ display: "grid" }}>
                                Seleziona Progetto
                            </InputLabel>
                            <Select
                                labelId="select-project"
                                variant="standard"
                                value={value || []}
                                // onChange={(event) => {
                                // const project_S = event.target.value;
                                // setValue('select_feed', []);
                                // setValue('select_version', []);
                                // setValue('select_trips', []);
                                // setValue('select_stroke', []);
                                // return onChange([project_S]);
                                // }}
                                onChange={(event) => {
                                    const project_S = event.target.value;
                                    if (project_S === initialValues.project[0]) {
                                      // Ripristina ai valori iniziali
                                      setValue('select_feed', initialValues.feed);
                                      setValue('select_version', initialValues.version);
                                      setValue('select_trips', initialValues.trips);
                                      setValue('select_stroke', initialValues.stroke);
                                    } else {
                                      // Svuota tutti i campi
                                      setValue('select_feed', []);
                                      setValue('select_version', []);
                                      setValue('select_trips', []);
                                      setValue('select_stroke', []);
                                    }
                                    return onChange([project_S]);
                                  }}
                                renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    <Chip label={selected} />
                                </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                <MenuItem disabled value=''>
                                <em>Progetto</em>
                                </MenuItem>
                                {projects.map((option, index) => (
                                <MenuItem key={index} value={option.name}>
                                    {option.name}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        )}
                    />

                </Grid>

                <Grid item xs={12} sm={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Divider orientation="vertical" flexItem sx={{ display: { xs: "none", sm: "flex" } }} />
                </Grid>


                <Grid item xs={12} sm={5} sx={{ textAlign: "center" }}>

                    <Controller
                        name="select_feed"
                        control={control}
                        rules={{ required: "Selezione obbligatoria" }}
                        render={({ field: { onChange, value } }) => (
                            <FormControl sx={{ display: "grid" }}>
                            <InputLabel id="select-feed" sx={{ display: "grid" }}>
                                Seleziona Feed
                            </InputLabel>
                            <Select
                                sx={{mb: 2}}
                                labelId="select-feed"
                                variant="standard"
                                value={value || []}
                                onChange={(event) => {
                                    const feed_S = event.target.value;
                                    if (feed_S === initialValues.feed[0]) {
                                      setValue('select_version', initialValues.version);
                                      setValue('select_trips', initialValues.trips);
                                      setValue('select_stroke', initialValues.stroke);
                                    } else {
                                      setValue('select_version', []);
                                      setValue('select_trips', []);
                                      setValue('select_stroke', []);
                                    }
                                    return onChange([feed_S]);
                                  }}
                                renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    <Chip label={selected} />
                                </Box>
                                )}
                                MenuProps={MenuProps}
                            >
                                <MenuItem disabled value=''>
                                <em>Feed</em>
                                </MenuItem>
                                {feeds?.filter((feed) => selected_project?.includes(feed.projectId.name))
                                .map((option, index) => (
                                    <MenuItem key={index} value={option.name}>
                                    {option.name}
                                    </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        )}
                    />

                    <Controller
                        name="select_version"
                        control={control}
                        rules={{ required: "Selezione obbligatoria" }}
                        render={({ field: { onChange, value } }) => (
                        <FormControl sx={{ display: "grid" }}>
                            <InputLabel
                            id="select-version"
                            sx={{ display: "grid" }}
                            >
                            Seleziona Versione
                            </InputLabel>
                            <Select
                            sx={{mb: 2}}
                            labelId="select-version"
                            variant="standard"
                            defaultValue=''
                            value={value || []}
                            onChange={(event) => {
                                const version_S = event.target.value;
                                if (version_S === initialValues.version[0]) {
                                  setValue('select_trips', initialValues.trips);
                                  setValue('select_stroke', initialValues.stroke);
                                } else {
                                  setValue('select_trips', []);
                                  setValue('select_stroke', []);
                                  const schema_S = versions.find((v) => v._id === version_S);
                                  setSchemas([{
                                    version: schema_S.name,
                                    schema: schema_S.namespace,
                                    feed: schema_S.feedSourceId?.name,
                                    project: schema_S.feedSourceId.projectId?.name
                                  }]);
                                }
                                return onChange([version_S]);
                              }}
                            renderValue={(selected) => 
                                {
                                return (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                <Chip label={schemas[0]?.version} />
                                </Box>
                            
                                )
                            }
                            }
                            MenuProps={MenuProps}
                            >
                            <MenuItem disabled value="">
                                <em>Versione</em>
                            </MenuItem>
                            {versions
                                .filter((version) => {
                                return selected_feed?.includes(version.feedSourceId?.name)
                                })
                                .map((option, index) => (
                                <MenuItem key={index} value={option._id}>
                                    <div>
                                    <Typography variant="body1">{option?.name}</Typography>
                                    {option.feedSourceId?.name && (
                                        <Typography
                                        variant="body2"
                                        color="textSecondary"
                                        display="block"
                                        >
                                        {option.feedSourceId.name}
                                        </Typography>
                                    )}
                                    </div>
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        )}
                    />

                    {/* ////////////////////// */}
                
                    {loading ? 
                    
                    (
                    <>
                        <Typography variant="body1" sx={{ mb: 2, mt: 2, textAlign: 'left' }}>Attendere...</Typography>
                        <Skeleton variant="rectangular" width="100%" height={56} sx={{mb: 2}} />
                        <Skeleton variant="rectangular" width="100%" height={56} />
                    </>

                     ) : (

                    <>
                    <Controller
                        name="select_trips"
                        control={control}
                        rules={{ required: "Selezione obbligatoria" }}
                        render={({ field: { onChange, value } }) => (
                            <FormControl sx={{ display: "grid" }}>
                            <InputLabel id="select-feed" sx={{ display: "grid" }}>
                                Seleziona Linea/e
                            </InputLabel>
                            <Select
                                sx={{mb: 2}}
                                labelId="select-trip"
                                variant="standard"
                                multiple
                                defaultValue=''
                                value={value || []}
                                onChange={(event) => {
                                    const selectedValues = event.target.value;
                    
                                    // reset select_stroke solo se le trips cambiano rispetto a quelle iniziali
                                    // controllo se tutte le trips (iniziali) sono presenti nella nuova selezione
                                    const allInitialTripsSelected = initialValues.trips.every(trip => selectedValues.includes(trip));
                                    let finalValues = [];
                    
                                    if (selectedValues.length === 0) {
                                        setValue('select_trips', []);
                                        setValue('select_stroke', []);
                                    } else if (selectedValues.includes('_all')) {
                                        const allSelected = trips.length === selectedValues.length - 1;
                                        finalValues = allSelected ? [] : trips.map(item => item.route_id);
                                        setValue('select_trips', finalValues);
                                        if (allSelected) {
                                            setValue('select_stroke', []);
                                        }
                                    } else {
                                        finalValues = selectedValues;
                                        setValue('select_trips', finalValues);
                                    }
                    
                                    if (allInitialTripsSelected) {
                                        setValue('select_stroke', initialValues.stroke);
                                    } else if (!arraysEqual(finalValues, initialValues.trips)) {
                                        setValue('select_stroke', []);
                                    }
                                    onChange(finalValues);
                                }}
                                    renderValue={(selected) => (
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {/* {selected.map((value, index) => (
                                    <Chip key={index} label={value} />
                                    ))} */}
                                    {selected.slice(0, 8).map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                    {selected.length > 8 && (
                                        <Chip label={`+${selected.length - 8} altri`} />
                                    )}
                                </Box>
                                )}
                                MenuProps={MenuProps}
                                >
                                <MenuItem disabled value=''>
                                <em>Linee</em>
                                </MenuItem>
                                {/* <MenuItem value="_all">
                                <ListItemIcon>
                                    <Checkbox
                                    value="_all"
                                    checked={value.length === trips.length}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Seleziona tutte" />
                                </MenuItem> */}
                                {trips
                                    .slice()
                                    .sort((a, b) => a.route_long_name.localeCompare(b.route_long_name))
                                    .map((option, index) => (
                                        <MenuItem key={index} value={option.route_id}>
                                        <ListItemIcon>
                                            <Checkbox
                                            checked={value.includes(option.route_id)}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={option.route_id}
                                            secondary={option.route_long_name}
                                        />
                                        </MenuItem>
                                    ))}
                            </Select>
                            </FormControl>
                        )}
                    />


                    <Controller
                        name="select_stroke"
                        control={control}
                        rules={{ required: "Selezione obbligatoria" }}
                        render={({ field: { onChange, value } }) => (
                            <FormControl sx={{ display: "grid" }}>
                            <InputLabel id="select-feed" sx={{ display: "grid" }}>
                                Seleziona Corsa/e
                            </InputLabel>
                            <Select
                                labelId="select-stroke"
                                variant="standard"
                                multiple
                                value={value || []}
                                onChange={(event) => {
                                    let stroke_S;
                                      if (event.target.value.includes('_all')) {
                                      // seleziono o deseleziono tutte le corse
                                      stroke_S = event.target.value.includes('_all') && value.length !== filteredStrokes.length 
                                      ? filteredStrokes.map(strokeItem => strokeItem.trip_id) 
                                      : [];
                                        
                                    } else {
                                      stroke_S = event.target.value;
                                    }
                                    console.log('Selected,stroke: ',stroke_S);
                                    setValue('select_stroke', stroke_S)
                                    return onChange(stroke_S);
                                  }}
                                renderValue={(selected) => (
                                 <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                    {selected.slice(0, 8).map((tripId) => {
                                    const stroke = filteredStrokes.find(stroke => stroke.trip_id === tripId);
                                    const label = stroke ? stroke.trip_short_name : "Caricamento..."; 
                                    return (
                                        <Chip key={tripId} label={label} />
                                    );
                                    })}
                                    {selected.length > 8 && (
                                    <Chip label={`+${selected.length - 8} altri`} />
                                    )}
                                </Box>
                                )}
                                MenuProps={MenuProps}
                                >
                                <MenuItem disabled value=''>
                                <em>Corse</em>
                                </MenuItem>
                                <MenuItem value="_all">
                                <ListItemIcon>
                                    <Checkbox
                                    value="_all"
                                    checked={value.length === filteredStrokes.length}
                                    />
                                </ListItemIcon>
                                <ListItemText primary="Seleziona tutte" />
                                </MenuItem>
                                {filteredStrokes
                                    .filter(strokeItem => selected_trips.includes(strokeItem.route.route_id))
                                    .map((option, index) => (
                                        <MenuItem key={index} value={option.trip_id}>
                                        <ListItemIcon>
                                            <Checkbox
                                            checked={value.includes(option.trip_id)}
                                            />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={option.trip_short_name}
                                            secondary={'Linea: ' + option.route.route_id + ' - ' + option.route.route_long_name}
                                        />
                                        </MenuItem>
                                    ))}
                            </Select>
                            </FormControl>
                        )}
                    />
                    </>
                     )
                    }

                </Grid>
            </Grid>

                    {/* ////////////////////// */}
                  
                    <Controller
                        name="is_custom"
                        control={control}
                        defaultValue={selectedValues?.is_custom}
                        render={({ field: { onChange, value } }) => (
                            <div style={{display: 'none'}}>
                                <Checkbox
                                    checked={value}
                                    disabled
                                />
                            </div>
                        )}
                    />
                    <br />
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                        <Box sx={{display: 'flex', justifyContent: "space-between", width: '100%'}}>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={() => setIsOpen(false)}
                            >
                                Annulla modifiche
                            </Button>
                            
                            <Button
                                type="submit"
                                variant="outlined"
                                disabled={!schemas.length || startDate > endDate}
                            >
                                Prosegui con la definizione
                            </Button>
                        </Box>
                        
                        {startDate > endDate && (
                            <Typography color="error" sx={{ mt: 2, textAlign: 'right' }}>
                            Verificare le date
                            </Typography>
                        )}
                        </Box>                
                </form>
        </DialogContent>
        </Dialog>
    );
}

export default UpdateCampaignDialog;