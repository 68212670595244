import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import { grey } from "@mui/material/colors";
import { Autocomplete, Box, Button, FormHelperText, Grid, TextField, Typography } from "@mui/material";
// import { registerLocale } from "react-datepicker";
import LogoRegioneAMP from "../../Img/LogoRegioneAMP.png";
import RilevazioniService from "../../services/rilevazioni.services";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useParams } from "react-router-dom";
import { useForm, Controller, useWatch } from "react-hook-form";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {it} from 'date-fns/locale'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: grey[800],
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
export const isThisAFieldsArrayContext = React.createContext(false);

export default function RecordingsGrid() {
  const { user } = useAuthenticator();
  let agency_id = user.attributes["custom:tpl_id"];
  let { recording_id, campaign_id } = useParams();

  const [values, setValues] = useState({ stops: [] });
  const [totalPeople, setTotalPeople] = useState([0]);
  const [defaultStartMonth, setDefaultStartMonth] = useState('');
  const [defaultEndMonth, setDefaultEndMonth] = useState('');
  const [opzioniTarga, setOpzioniTarga] = useState([]);
  const [sedutiValues, setSedutiValues] = useState([]);
  const [inpiediValues, setInpiediValues] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [capienzaTotale, setCapienzaTotale] = useState(0);
  // eslint-disable-next-line no-unused-vars
  let total_passenger = 0;

  const navigate = useNavigate();


  useEffect(() => {
    getDetection(agency_id, campaign_id, recording_id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetection = (agency_id, campaign_id, recording_id) => {
    RilevazioniService.getDetection(agency_id, campaign_id, recording_id).then(
      (data) => {
        console.log("🚀 ~ getDetection ~ data:", data)
        const date =
          data.data_rilievo != null
            ? moment(data.data_rilievo, "DD-MM-YYYY").format("YYYY-MM-DD")
            : "";
        const rilevat = data.rilevatore ? data.rilevatore : "";
        const targa = data.targa != null ? data.targa : "";
        const post_sed = data.posti_a_sedere != null ? data.posti_a_sedere : "";
        const post_in_pie =
          data.posti_in_piedi != null ? data.posti_in_piedi : "";
        const note = data.note != null ? data.note : "";
        /// prova
        const defaultStops = data.stops.map((stop) => ({
          ...stop,
          saliti: stop.saliti != null ? stop.saliti : "",
          discesi: stop.discesi != null ? stop.discesi : "",
        }));
        setValues({
          ...data,
          data_rilievo: date,
          rilevatore: rilevat,
          targa: targa,
          posti_a_sedere: post_sed,
          posti_in_piedi: post_in_pie,
          note: note,
          stops: defaultStops,
        });
        // console.log("detection", data);
      }
    );
  };

  const fetchLicensePlate = async (inputValue) => {
    try {
      if (inputValue && inputValue.length >= 2) {
        const response = await RilevazioniService.getLicensePlates(inputValue);
        //console.log("Risposta API:", response);
        if (response && response.veicoli) {
          const veicoliData = response.veicoli.map((veicolo) => ({
            targa: veicolo.targa,
            seduti: veicolo.seduti,
            inpiedi: veicolo.inpiedi,
          }));
  
          const responseUpdated = ["", ...veicoliData.map((veicolo) => veicolo.targa)];
          setOpzioniTarga(responseUpdated);
  
          const sedutiValues = veicoliData.map((veicolo) => veicolo.seduti);
          setSedutiValues(sedutiValues);
  
          const inpiediValues = veicoliData.map((veicolo) => veicolo.inpiedi);
          setInpiediValues(inpiediValues);

          const postiTotali = veicoliData.map((veicolo) => veicolo.seduti + veicolo.inpiedi);
          setCapienzaTotale(postiTotali)
        } else {
          console.log("La risposta API non contiene 'targhe'");
        }
      } else {
        setOpzioniTarga([]);
        setSedutiValues([]);
        setInpiediValues([]);
      }
    } catch (error) {
      console.error('Errore durante il fetch delle targhe:', error);
    }
  };
  
  useEffect(() => {
    fetchLicensePlate(inputValue);
  }, [inputValue]);

  useEffect(() => {
    reset(values);
  }, [values]); // eslint-disable-line react-hooks/exhaustive-deps

  const { control, handleSubmit, reset, formState, setValue, getValues, watch } = useForm({
    defaultValues: {
      data_rilievo: "",
      rilevatore: "",
      targa: "",
      posti_a_sedere: "",
      posti_in_piedi: "",
      stops: {
        saliti: 0,
        discesi: 0,
        note: "",
      },
    },
  });

  const watchedFields = watch("targa");
  console.log(watchedFields); 

  const watchedValues = useWatch({
    control,
    name: 'stops',
    defaultValue: values.stops,
  });
  useEffect(() => {
    let totalPeople = [];
    let cumulativeTotal = 0;
  
    for (let i = 0; i < watchedValues.length; i++) {
      const got_on = watchedValues[i]?.saliti ?? 0;
      const got_off = watchedValues[i]?.discesi ?? 0;
      const passengers = got_on - got_off;
      
      cumulativeTotal += passengers;
      totalPeople.push(cumulativeTotal);
    }
  
    setTotalPeople(totalPeople);
  }, [watchedValues]);



  const onSubmit = (data) => {
    // console.log("🚀 ~ onSubmit ~ dataSend:", data)
    const { targa } = data;
    const valoreTarga = targa || "";
    const stops = data.stops.map((stop) => ({
      got_on: stop.sequence === (data.stops.length -1) ? 0 : parseInt(stop.saliti),
      got_off: stop.sequence === 0 ? 0 : parseInt(stop.discesi),
      stop_times_id: stop.id,
      stop_sequence: stop.sequence,
    }));

    const data_to_send = {
      id_rilevazione: values.id,
      data_rilievo: data.data_rilievo,
      rilevatore: data.rilevatore,
      targa: valoreTarga,
      posti_a_sedere: parseInt(data.posti_a_sedere),
      posti_in_piedi: parseInt(data.posti_in_piedi),
      note: data.note,
      stops: stops,
    };

    RilevazioniService.createDetection(data_to_send)
      .then((data) => {
        document.body.style.pointerEvents = 'none';
        Swal.fire({
          icon: "success",
          title: "Compilata",
          text: "La scheda è stata compilata correttamente",
          toast: true,
          timer: 2500,
          showConfirmButton: false,
          didClose: () => {
            document.body.style.pointerEvents = 'auto';
        }
});
        setTimeout(() => {
          navigate(-1);
        }, 2000);
      })
      .catch((err) => {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Errore",
          text: err.message,
          toast: true,
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        });
      });
  };
  
  useEffect(() => {
    if (values.campagna_start) {
      const newDefaultStartMonth = moment(values.campagna_start, 'DD-MM-YYYY').format('YYYY-MM-DD');
      setDefaultStartMonth(newDefaultStartMonth);
    }
    if (values.campagna_end) {
      const newDefaultEndMonth = moment(values.campagna_end, 'DD-MM-YYYY').format('YYYY-MM-DD');
      setDefaultEndMonth(newDefaultEndMonth);
    }
  }, [values.campagna_start, values.campagna_end]);

  return (
    <>
      <Grid
        sx={{ display: "flex", justifyContent: "space-between", marginTop: 5 }}
      >
        <img src={LogoRegioneAMP} alt="Logo" />
          <Typography component={"div"} variant="h5" sx={{ textAlign: "center" }}>
            <b>NUMERO SCHEDA: </b>
            <br />
            <br />
            {values.id} &nbsp;
          </Typography>
        <div></div>
      </Grid>
      <Box sx={{ marginTop: 5 }}>
          <Typography component={"span"} variant="body1">
            <b>CAMPAGNA: </b> {values.campagna}
          </Typography>
          <br />
          <Typography component={"span"} variant="body1">
            <b>NUMERO CONTRATTO: </b> {values.contratto} &nbsp; <b>RILIEVO FREQUENTAZIONI:&nbsp;</b> { 'dal: ' + values.campagna_start + ' al: ' + values.campagna_end}
          </Typography>
        &nbsp;
        <br />
          <Typography component={"span"} variant="body1">
            <b>AZIENDA: </b> {values.azienda}
          </Typography>
        &nbsp;&nbsp;
        <br />
          <Typography component={"span"} variant="body1">
            <b>LINEA: </b> {values.linea}
          </Typography>
        &nbsp;&nbsp;
          <Typography component={"span"} variant="body1">
            <b>PERCORSO: </b> {values.percorso} <b>DA: </b> {values.percorso_da} <b> A: </b> {values.percorso_a}
          </Typography>
        &nbsp;&nbsp;
        <br />
          <Typography component={"span"} variant="body1">
            <b>CORSA: </b> {values.corsa} &nbsp;<b>FREQUENZA: </b> {values.frequenza} 
            <b> RILEVAZIONE: </b> {values.rilevazione} <b>DI </b> {values.totale_rilevazioni} &nbsp; <b>RILIEVI PREVISTI DELLA CORSA</b>
          </Typography>
      </Box>
      <br />
      {/* /////////// INIZIO FORM /////////// */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box display="flex" alignItems="center">
                <Typography component={"span"} variant="body1">
                  <b>DATA RILIEVO: </b>
                </Typography>
                &nbsp;
                    <Controller
                      name="data_rilievo"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                        <DatePicker
                          label="Data Inizio"
                          inputFormat="dd/MM/yyyy"
                          value={value ? moment(value, 'YYYY-MM-DD').toDate() : null}
                          minDate={defaultStartMonth ? moment(defaultStartMonth, 'YYYY-MM-DD').toDate() : null}
                          maxDate={defaultEndMonth ? moment(defaultEndMonth, 'YYYY-MM-DD').toDate() : null}
                          views={['year', 'month', 'day']}
                          openTo="day"
                          defaultCalendarMonth={defaultStartMonth ? moment(defaultStartMonth, 'YYYY-MM-DD').toDate() : null}
                          onChange={(newValue) => {
                            onChange(moment(newValue).format('YYYY-MM-DD'));
                          }}
                          PaperProps={{
                            sx: {
                              '& .MuiPickersDay-dayWithMargin:focus': {
                                backgroundColor: 'transparent',
                              }
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              sx={{ display: "grid" }}
                              {...params}
                              inputProps={{ ...params.inputProps, readOnly: true }} // evitare l'inserimento dattiloscritto della data
                              id="start_date"
                              variant="standard"
                              error={!!error}
                              helperText={error ? error.message : null}
                            />
                          )}
                        />
                      </LocalizationProvider>
      
                      )}
                      rules={{ required: "Inserire valore" }}
                    />
                &nbsp;
                <Typography> <b> RILEVATORE: </b> </Typography>
                &nbsp;
                    <Controller
                      name="rilevatore"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div>
                          <TextField
                            sx={{ width: 270 }}
                            size="small"
                            variant="standard"
                            type={"text"}
                            value={value ||""}
                            onChange={onChange}
                            error={!!error}
                          />
                        </div>
                      )}
                      rules={{ required: "Inserire valore" }}
                    />
                &nbsp;
                <Typography> <b> TARGA: </b> </Typography>
                &nbsp;
                    <Controller
                      name="targa"
                      control={control}
                      render={({ field, fieldState: { error } }) => (
                        <div>
                          <Autocomplete
                            size="small"
                            disablePortal
                            id="autocomplete-targa"
                            options={opzioniTarga}
                            getOptionLabel={(option) => option || ""}
                            sx={{ width: 150 }}
                            value={field.value || ""}
                            onChange={(event, newValue) => {
                              field.onChange(newValue || "");
                              setInputValue(newValue || "");
                              if (!newValue) {
                                setValue('posti_a_sedere', null);
                                setValue('posti_in_piedi', null);
                              } else {
                                const selectedIndex = opzioniTarga.indexOf(newValue);

                                if (selectedIndex !== -1) {
                                  // imposto indice a -1 perchè aggiungo "" ad araay di targhe per warning material
                                  const selectedSeduti = sedutiValues[selectedIndex - 1];
                                  const selectedInpiedi = inpiediValues[selectedIndex - 1];
                                  setValue('posti_a_sedere', selectedSeduti);
                                  setValue('posti_in_piedi', selectedInpiedi);
                                }
                              }
                            }}
                            onInputChange={(event, newInputValue) => {
                              const inputUpperCase = newInputValue.toUpperCase(); 
                              setInputValue(inputUpperCase);
                              fetchLicensePlate(inputUpperCase);
                            }}
                            isOptionEqualToValue={(option, value) => option === value || option === ''}
                            renderInput={(params) => (
                              <div>
                                <TextField
                                  {...params}
                                  variant="standard"
                                  error={!!error || !field.value}
                                />
                                <FormHelperText sx={{position:'absolute' ,marginTop: 1,marginLeft:-4 , color: 'red', fontSize: 12 }}>
                                  {(error && error.message) || (!field.value && "Obbligatorio per inserire passeggeri")}
                                </FormHelperText>
                              </div>
                              // <TextField
                              //   {...params}
                              //   variant="standard"
                              //   // error={!!error}
                              //   error={!!error || !field.value} // Aggiunto controllo manuale per lo stato di errore
                              //   helperText={(error && error.message) || (!field.value && "Inserire valore")}
                              // />
                            )}
                          />
                        </div>
                            )}
                      rules={{required: "Inserire valore"}}
                    />          
                &nbsp;
                <Typography> <b> POSTI OFFERTI: </b> SEDUTI:&nbsp; </Typography>
                &nbsp;
                    <Controller
                      name="posti_a_sedere"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          sx={{ width: 90 }}
                          size="small"
                          variant="standard"
                          type={"number"}
                          disabled
                          InputProps={{ inputProps: { min: 0 } }}
                          value={value ||""}
                          onChange={onChange}
                          error={!!error}
                        />
                      )}
                      rules={{ required: "Inserire valore" }}
                    />
                &nbsp;
                <Typography>&nbsp; E IN PIEDI:&nbsp;</Typography>
                &nbsp;
                    <Controller
                      name="posti_in_piedi"
                      control={control}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          sx={{ width: 90 }}
                          size="small"
                          variant="standard"
                          type={"number"}
                          disabled
                          InputProps={{ inputProps: { min: 0 } }}
                          value={value || ""}
                          onChange={onChange}
                          error={!!error}
                        />
                      )}
                      rules={{ required: "Inserire valore" }}
                    />
            </Box>
            <br />
            <br />
            <Box>
              <TableContainer sx={{ marginLeft: 15, display: "contents" }}>
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell></StyledTableCell>
                      <StyledTableCell align="left">Fermata</StyledTableCell>
                      <StyledTableCell align="left">Orario</StyledTableCell>
                      <StyledTableCell align="left">Saliti</StyledTableCell>
                      <StyledTableCell align="left">Discesi</StyledTableCell>
                      <StyledTableCell align="left">A Bordo</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <isThisAFieldsArrayContext.Provider value={true}>
                      {values.stops?.map((stop,index) => {
                        const { id, nome_fermata, arrivo } = stop;
                        const got_on_name = `stops[${stop.sequence}].saliti`;
                        const got_off_name = `stops[${stop.sequence}].discesi`;
                        const discesi_fermata = getValues(got_off_name);
                        total_passenger += stop.saliti - stop.discesi;

                        return (
                          <StyledTableRow key={id + 1} >
                            <StyledTableCell component="th" scope="row">
                              {stop.sequence + 1}
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              {nome_fermata}
                            </StyledTableCell>
                            <StyledTableCell align="left">{arrivo}</StyledTableCell>
                            <StyledTableCell align="left" width={100}>
                              <Controller
                                name={got_on_name}
                                defaultValue={(stop.sequence === values.stops.length - 1) ? 0 : 0}
                                control={control}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <Box height={60}>
                                    <TextField
                                      disabled={
                                        stop.sequence === values.stops.length - 1 ||
                                        !watchedFields
                                      }
                                      value={value}
                                      size="small"
                                      type="number"
                                      InputProps={{ inputProps: { min: 0 } }}
                                      onChange={(e) => {
                                        let newValue = parseInt(e.target.value, 10);
                                          if (isNaN(newValue)) {
                                            // Se il nuovo valore non è un numero, lo imposta a vuoto
                                            onChange('');
                                          } 
                                          else {
                                            // if (newValue > capienzaTotale) {
                                            //   // Se supera il massimo, imposta il valore al massimo consentito
                                            //   newValue = capienzaTotale;
                                            // }
                                            onChange(newValue.toString());
                                          }
                                      }}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                    />
                                  </Box>
                                )}
                                rules={(stop.sequence === values.stops.length - 1) ? {} : { required: "Inserire valore" }}
                                />
                            </StyledTableCell>
                            <StyledTableCell align="left" width={100}>
                              <Controller
                                name={got_off_name}
                                defaultValue={stop.sequence === 0 ? 0 : 0}
                                control={control}
                                render={({
                                  field: { onChange, value },
                                  fieldState: { error },
                                }) => (
                                  <Box height={60}>
                                    <TextField
                                      disabled={
                                        stop.sequence === 0 ||
                                        !watchedFields
                                      }
                                      value={value}
                                      size="small"
                                      type="number"
                                      InputProps={{ inputProps: { min: 0 } }}
                                      onChange={(e) => {
                                        onChange(e.target.value);
                                      }}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                    />
                                  </Box>
                                )}
                                rules={stop.sequence === 0 ? {} : { required: "Inserire valore" }}
                                />
                            </StyledTableCell>
                            <StyledTableCell align="left" width={120}>
                            <Box height={60}>
                                <Controller
                              name={`totalPeople[${index}]`}
                              control={control}
                              defaultValue={totalPeople[index] || 0}
                              render={({ field, fieldState }) => (
                                <Box height={60}>
                                  <TextField
                                    {...field}
                                    value={totalPeople[index] || 0}
                                    size="small"
                                    disabled
                                    error={
                                      // errore form
                                      fieldState.invalid ||
                                      // se a bordo è negativo
                                      totalPeople[index] < 0 ||
                                      // se si supera capienza totale
                                      (capienzaTotale && totalPeople[index] > capienzaTotale) ? true : false ||
                                      // se l'ultimo a bordo è diverso da 0
                                      (index === totalPeople.length - 1 && totalPeople[index] !== 0) ||
                                      // se i discesi superano i presenti a bordo della riga prima e non ci sono discesi segnati
                                      (index !== totalPeople.length - 1 && discesi_fermata > totalPeople[index - 1]  && discesi_fermata !== '')
                                    }
                                    helperText={
                                      fieldState.invalid
                                        ? fieldState?.error?.message
                                        : totalPeople[index] < 0
                                        ? 'Controllare valori'
                                        : capienzaTotale && totalPeople[index] > capienzaTotale
                                        ? 'Capienza superata'
                                        : index === totalPeople.length - 1 && totalPeople[index] !== 0
                                        ? 'Deve essere 0'
                                        : (index !== totalPeople.length - 1 && discesi_fermata > totalPeople[index -1]  && discesi_fermata !== '')
                                        ? 'Incoerenza'
                                        : ''
                                    }
                                  />
                                </Box>
                              )}
                              rules={{
                                validate: (value) => {
                                  if (totalPeople[index] < 0 ) {
                                    return "Controllare valori";
                                  }
                                  // if (index === totalPeople.length - 1 && totalPeople[index] !== 0) {
                                  //   return 'L\'ultimo valore deve essere 0';
                                  // }
                                  if ((capienzaTotale && totalPeople[index] > capienzaTotale) ? true : false) {
                                    return 'Capienza superata';
                                  }
                                  if (index !== totalPeople.length - 1 && discesi_fermata > totalPeople[index -1]  && discesi_fermata !== '') {
                                    return 'Incoerenza';
                                  }
                                  return true; // validazione è passata
                                },
                              }}
                            />

                                {/* <TextField
                                  value={totalPeople[index] || 0}
                                  size="small"
                                  disabled
                                  error={totalPeople[index] < 0 || (capienzaTotale && totalPeople[index] > capienzaTotale) ? true : false}
                                  // error={totalPeople[index] < 0 || (capienzaTotale && totalPeople[index] > capienzaTotale)}
                                  helperText={
                                    totalPeople[index] < 0 
                                      ? "Controllare valori" 
                                      : capienzaTotale && totalPeople[index] > capienzaTotale
                                      ? "Capienza superata"
                                      : ""
                                  }
                                /> */}
                                
                            </Box>                              
                            </StyledTableCell>
                          </StyledTableRow>
                        );
                      })}
                    </isThisAFieldsArrayContext.Provider>
                  </TableBody>
                </Table>
              </TableContainer>
              <Typography variant="caption" >
                NB: I saliti alla prima fermata includono anche gli eventuali passeggeri già presenti a bordo da una precedente corsa concatenata mentre i discesi all'ultima fermata includono anche gli eventuali passeggeri che restano a bordo per una successiva corsa concatenata 
              </Typography>
              <Controller
                name="note"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextareaAutosize
                    maxRows={3}
                    aria-label="maximum height"
                    placeholder="Note"
                    defaultValue=""
                    value={value}
                    onChange={onChange}
                    style={{ width: 800, height: 80 }}
                  />
                )}
              />
                <Box marginTop={2} display="flex" flexDirection="column" alignItems="flex-start">
                    <Button 
                        type="submit" 
                        variant="outlined" 
                        disabled={
                          !formState.isValid || 
                          totalPeople[totalPeople.length - 1] !== 0 ||
                          user?.getSignInUserSession()?.getAccessToken()?.payload["cognito:groups"].includes('adminReadOnly')
                          }>
                      Salva scheda
                    </Button>
                        <Box sx={{ display:"flex",width: "13vw", mt: 2 }}>
                        {!formState.isValid && (
                          <Typography color="error" variant="caption" >
                            Verificare che tutti i campi siano compilati o la capienza non sia stata superata
                          </Typography>
                        )}
                        </Box>
                </Box>
            </Box>
          </form>
    </>
  );
}
