import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import StripedDataGrid from "../Style/StripedDataGrid";
import "../Style/List.css";
import {
  itIT,
  GridToolbarContainer,
  GridActionsCellItem,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import {
  Tooltip,
  Fade,
  Switch,
  CircularProgress,
  Box,
  Typography,
  // Dialog,
  // DialogTitle,
  // DialogContent,
} from "@mui/material";
import {
  Edit as EditIcon,
  Assignment as AssignmentIcon,
  FolderZip as FolderZipTwoToneIcon,
  DeleteForever as DeleteForeverIcon,
  // Construction as ConstructionIcon,
  HourglassTop as HourglassTopIcon,
  ReportProblem as ReportProblemIcon
} from "@mui/icons-material";
import CampaignService from "../../services/campaign.services";
import { useAuthenticator } from "@aws-amplify/ui-react";
import UpdateCampaign from "./UpdateCampaign";
import UpdateCampaignCustom from "./UpdateCampaignCustom";
import CampaignServices from "../../services/campaign.services";

const ListCampaigns = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  let agency_id = user.attributes["custom:tpl_id"];
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  ////////// column `actions` visible → hidden //////////////
  const [pageSize, setPageSize] = useState(15);
  const [visible, setVisible] = useState(false);
  const [superAdminActions, setSuperAdminActions] = useState(true);
  const [values, setValues] = useState({})
  // eslint-disable-next-line no-unused-vars
  const [progress, setProgress] = useState(1.01)
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    actions: true,
  });
  const [checked, setChecked] = useState({});
  const [open, setOpen] = useState(false);
  const [openCustom, setOpenCustom] = useState(false);
  // const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [refreshNeeded, setRefreshNeeded] = useState(0);

  const refresh = () => {
    // console.log('refresh')
    setRefreshNeeded(prev => prev + 1);
  }



  function CircularProgressWithLabel(props) {
    return props.value > 100 ? null : (
        <Box sx={{
          'z-index': '10060',
          position: 'fixed',
          top: '55%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <CircularProgress variant="determinate" {...props} />
          <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
          >
            <Typography variant="caption" component="div" color="text.secondary">
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
    );
  }

  
  useEffect(() => {
    checkUserRole();
    getCampaigns(agency_id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshNeeded]);

  useEffect(() => {
    const status = {};
    campaigns.forEach((campaign) => {
      status[campaign.id] = campaign.public;
    });
    setChecked((prevState) => ({ ...prevState, ...status }));
  }, [campaigns]); // eslint-disable-line react-hooks/exhaustive-deps
  const checkUserRole = () => {
    if (!user) return;

    const currentAccount = user?.getSignInUserSession()?.getAccessToken()
      ?.payload["cognito:groups"];
    if (currentAccount.includes("admin")) {
      setColumnVisibilityModel({ ...columnVisibilityModel, actions: true });
      setVisible(true);
      setSuperAdminActions(true);
    }
    if (currentAccount.includes("adminReadOnly")) {
      setColumnVisibilityModel({ ...columnVisibilityModel, actions: true });
      setVisible(true);
      setSuperAdminActions(false);
    }
    if (currentAccount.includes("adminCompany")) {
      setColumnVisibilityModel({ ...columnVisibilityModel, actions: true });
      setVisible(true);
    }
  };

  //////////////////////////////////////////
  const getCampaigns = async (agency_id) => {
    try {
      const data = await CampaignService.getAllCampaigns(agency_id);
      const isAdminReadOnly = user?.getSignInUserSession()?.getAccessToken()
        ?.payload["cognito:groups"].includes('adminReadOnly');
      
      if (isAdminReadOnly) {
        setCampaigns(data.filter((campaign) => campaign.public));
      } else {
        setCampaigns(data);
      }
    } catch (error) {
      console.error('Errore nel recuperare le campagne:', error);
      // Gestisci eventuali errori qui
    } finally {
      setIsLoading(false);
    }
  };

  // const getCampaigns = (agency_id) => {
  //   CampaignService.getAllCampaigns(agency_id).then((data) => {
  //     if (user?.getSignInUserSession()?.getAccessToken()
  //         ?.payload["cognito:groups"].includes('adminReadOnly'))
  //       setCampaigns(data.filter((campaign) => campaign.public));
  //     else
  //       setCampaigns(data);
  //     setIsLoading(false);
  //   });
  // };

  const handleChangeChecked = (id) => {
    const updatedChecked = { ...checked };
    updatedChecked[id] = !updatedChecked[id];
    setChecked((prevState) => ({ ...prevState, ...updatedChecked }));

    if (updatedChecked[id]) {
      Swal.fire({
        title: "Vuoi davvero rendere pubblica questa campagna?",
        text: "Questa modifica non è reversibile!",
        icon: "warning",
        toast: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Si, rendi pubblica!",
        cancelButtonText: "No, annulla",
        cancelButtonColor: "#d33"
      }).then((result) => {
        if (result.isConfirmed) {
          // La pubblicazione è stata confermata, disabilita lo switch
          setChecked({ ...checked, [id]: true });
          // Mostra il popup di successo dopo un certo tempo
          CampaignService.getFlags(id).then(() => Swal.fire({
              title: "Esito positivo",
              text: "Campagna pubblicata correttamente, non è più possibile apportare modifiche",
              icon: "success",
              timer: 3000,
              toast: true,
              showConfirmButton: false,
              position: "center",
              background: "#DAF7A6",
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            }))
        } else {
          // La pubblicazione è stata annullata, ripristina il valore precedente dello switch
          setChecked({ ...checked, [id]: false });
        }
      });
    }
  };

  const handleZip = (id, agency_id, campaign_name,zip_status, checkedId) => {
    if (zip_status===-1){
      Swal.fire({ 
        title: "Attenzione",
        text: "Errore in fase di generazione PDF: consultare CloudWatch per i dettagli",
        icon: "error",
        toast: true,
        //showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
        //cancelButtonText: "Annulla",
      })
    } else if (zip_status<-1) {
      Swal.fire({ 
        title: "Attenzione",
        text: "Errore: errore in fase di lancio batch job su AWS",
        icon: "error",
        toast: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      })
    } else if (zip_status===0 && checkedId === false) {
      Swal.fire({ 
        title: "Avviso",
        text: "Non è possibile generare il file .zip perchè la campagna non è pubblica",
        icon: "warning",
        toast: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
        //cancelButtonText: "Annulla",
      })
      } else if (zip_status===1 || (checkedId === true && zip_status ===0)) {
      Swal.fire({ 
        title: "Attenzione",
        text: "La generazione dei file è in corso. Si prega di attendere...",
        icon: "warning",
        toast: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
        //cancelButtonText: "Annulla",
      }).then((result) => {
        if (result.isConfirmed) {
          refresh();
        }
      })
    } else if (zip_status === 2) {
      Swal.fire({
        title: "Conferma",
        text: "Sei sicuro di voler scaricare lo .Zip? \r Potrebbe richiedere del tempo.",
        icon: "warning",
        toast: true,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Sì, scarica !",
        cancelButtonText: "Annulla",
        cancelButtonColor: "#d33"
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: 'Scaricamento .Zip in corso',
            html: 'Attendere prego...',
            icon: "info",
            //allowOutsideClick: false,
            allowEscapeKey: false,
            //allowEnterKey: false,
            didOpen: () => {
              Swal.showLoading();
            },
          });
          //Swal.showLoading()
          CampaignServices.downloadPdf(agency_id, id).then(async (paths) => {
          //function getRealtimeData(data) {
            Swal.close()
              console.log(paths)
              Swal.fire({
                title: 'Completamento in corso',
                html: 'Attendere prego...',
                icon: "info",
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                didOpen: () => {
                  Swal.showLoading();
                },
              });
              CampaignServices.downloadZip(await paths.json(), id, campaign_name).then(() => {
                Swal.close()
                Swal.fire({
                title: "Esito positivo",
                text: "Zip generato correttamente",
                icon: "success",
                timer: 2000,
                toast: true,
                showConfirmButton: false,
                position: "center",
                background: "#DAF7A6",
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              })
            })
        })
        }
      })
    }
  };
  
  const goToDetections = (id) => {
    navigate("/listaRilevazioni/" + id);
  };
  
  const deleteCampaign = (id) => {
    Swal.fire({
      title: "Vuoi davvero eliminare questa campagna?",
      text: "Questa modifica non è reversibile!",
      icon: "warning",
      toast: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Si, elimina definitivamente!",
      cancelButtonText: "No, annulla",
      cancelButtonColor: "#d33"
    }).then((result) => {
      if (result.isConfirmed) {
        CampaignService.deleteSingleCampaign(id, agency_id).then((res) => {
          if (res.error) {
            Swal.fire({
              icon: "error",
              title: res.message,
              text: res.error,
              toast: true,
              showClass: {
                popup: "animate__animated animate__fadeInDown",
              },
              hideClass: {
                popup: "animate__animated animate__fadeOutUp",
              },
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Cancellazione eseguita con successo",
              confirmButtonColor: "#3085d6",
              toast: true,
              timer: 3000,
              timerProgressBar: true,
            });
            getCampaigns(agency_id)
          }
        });
      }
    });
  };
  
  function editRowDB(row) {
    const array_start_date = row.start_date.split('-')
    const array_end_date = row.end_date.split('-')
    
    setValues({...row, start_date: new Date(array_start_date[2], array_start_date[1] - 1, array_start_date[0]),
      end_date:new Date(array_end_date[2], array_end_date[1] - 1, array_end_date[0])})
      if(row.is_custom){
        setOpenCustom(true);
      }else {
        setOpen(true);
      }
      console.log(row.is_custom);
    }

    const getTooltipTitle = (id) => {
      return checked[id] === true ? "Campagna pubblica" : "Rendi pubblica";
    };
    
    const getZipIcon = (zip_status, checkedId) => {
      switch (true) {
        case zip_status < 0:
          return <ReportProblemIcon color="warning" />;
        // case zip_status === 0: 
        //   return <FolderZipTwoToneIcon color="disabled" />;
        case zip_status === 2:
          return <FolderZipTwoToneIcon color="success" />;
        default:
              return checkedId === false ? <FolderZipTwoToneIcon color="disabled"/> : <HourglassTopIcon color="disabled" />;
            }
    }
          
    const getTooltipContent = (zip_status, checkedId) => {
        if (zip_status === -1) {
          return "Errore in fase di generazione PDF: consultare CloudWatch per i dettagli";
        } else if (zip_status < -1) {
          return "Errore: errore in fase di lancio batch job su AWS";
        //} else if ((zip_status === 0 && checkedId === true) || (zip_status === 1 && checkedId === true)){
        } else if ((zip_status === 0 || zip_status === 1) && checkedId === true) { 
          return "Attendere, generazione in corso ..."
        } else if (zip_status === 0){
          return "Genera .Zip"
        } else if(zip_status === 2){
          return "Scarica .Zip"
        }
    }

  const columns = [
    {
      field: "name",
      headerName: "Nome Campagna",
      flex: 0.8,
    },
    {
      field: "is_custom",
      headerName: "Custom",
      flex: 0.4,
      type: "boolean"
    },
    {
      field: "start_date",
      headerName: "Data Inizio",
      flex: 0.6,
    },
    {
      field: "end_date",
      headerName: "Data Fine",
      flex: 0.6,
    },
    {
      field: "feed_campaigns",
      headerName: "Feed",
      filterable: false,
      flex: 3,
      renderCell: (params) => (
        <>
          <span>
            {params.value.map((feed_campaigns) => (
              <span variant="subtitle2" key={feed_campaigns.project +
                "→" +
                feed_campaigns.feed +
                "→" +
                feed_campaigns.version}>
                {feed_campaigns.project +
                  "→" +
                  feed_campaigns.feed +
                  "→" +
                  feed_campaigns.version}
                <br />
              </span>
            ))}
          </span>
        </>
      ),
    },
    {
      field: "actions",
      type: "actions",
      filterable: false,
      flex: 1.2,
      // visibleable: visible,
      hideable: visible,
      getActions: ({ id, row }) => {
        const actions = [
          <GridActionsCellItem
            key={id}
            label="Visualizza dettagli"
            onClick={() => goToDetections(id)}
            color="inherit"
            icon={
              <Tooltip
                title={
                  <span style={{ margin: 1, fontSize: 13 }}>
                    Visualizza dettagli
                  </span>
                }
                transitioncomponent={Fade}
                transitionprops={{ timeout: 800 }}
                followcursor="true"
                sx={{ fontSize: 20 }}
              >
                <AssignmentIcon />
              </Tooltip>
            }
          />,
          <GridActionsCellItem
            key={id}
            label="checked"
            icon={
              <Tooltip
                title={
                  <span style={{ margin: 1, fontSize: 13 }}>
                    {getTooltipTitle(id)}
                  </span>
                }
                transitioncomponent={Fade}
                transitionprops={{ timeout: 800 }}
                followcursor="true"
                sx={{ fontSize: 20 }}
              >
                <span>
                  <Switch
                    checked={checked[id] || false}
                    onChange={() => handleChangeChecked(id)}
                    size="small"
                    disabled={checked[id] === true}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </span>
              </Tooltip>
            }
          />,
          <GridActionsCellItem
            key={id}
            label="generate_zip"
            className="textPrimary"
            onClick={() => handleZip(id, agency_id, row.name,row.zip_status,checked[id])}
            color="inherit"
            icon={
              <Tooltip
                title={
                  <span style={{ margin: 1, fontSize: 13 }}>
                    {getTooltipContent(row.zip_status, checked[id])}
                  {/* {checked[id] === false ? 'Campagna non pubblica':row.zip_status===2 ? 'Scarica .Zip' : 'Generazione .Zip in corso...'} */}
                  </span>
                }
                transitioncomponent={Fade}
                transitionprops={{ timeout: 800 }}
                followcursor="true"
                sx={{ fontSize: 20 }}
              >
                {/* {checked[id] === false?<FolderZipTwoToneIcon color="disabled"/> : row.zip_status===2 ? <FolderZipTwoToneIcon color="success"/> : <HourglassTopIcon color="disabled" />} */}
                {getZipIcon(row.zip_status, checked[id])}
              </Tooltip>
            }
          />,
        ];
        if (superAdminActions)
          actions.push(
            <GridActionsCellItem
              key={id}
              label="Edit"
              className="textPrimary"
              disabled={checked[id]}
              onClick={() => {
                editRowDB(row);
              }}
              color="inherit"
              icon={
                <Tooltip
                  title={
                    <span style={{ margin: 1, fontSize: 13 }}>
                      Modifica campagna
                    </span>
                  }
                  transitioncomponent={Fade}
                  transitionprops={{ timeout: 800 }}
                  followcursor="true"
                  sx={{ fontSize: 20 }}
                >
                  <EditIcon />
                </Tooltip>
              }
            />,
            <GridActionsCellItem
              // key={id}
              label="delete_campaign"
              className="textPrimary"
              disabled={checked[id]}
              onClick={() => deleteCampaign(id)}
              color="inherit"
              icon={
                <Tooltip
                  title={
                    <span style={{ margin: 1, fontSize: 13 }}>
                      Elimina la campagna
                    </span>
                  }
                  transitioncomponent={Fade}
                  transitionprops={{ timeout: 800 }}
                  followcursor="true"
                  sx={{ fontSize: 20 }}
                >
                  <DeleteForeverIcon />
                </Tooltip>
              }
            />
          );
        return actions;
      },
    },
    {
      field: "status",
      headerName: "Schede Completate",
      flex: 0.7,
      renderCell: (params) => (
        <>
          <span>{params.value.completed + " di " + params.value.total}</span>
        </>
      ),
    },
    {
      field: "completion_percentage",
      headerName: "Percentuale",
      flex: 0.5,
      valueFormatter: (params) => {
        if (params.value == null) {
          return "";
        }
        return `${params.value.toFixed(2)} %`;
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarDensitySelector />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }
  return (
    <>
      <div style={{ height: '70vh', width: "100%" }}>
        <StripedDataGrid
        key={refreshNeeded}
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[15, 25, 35, 45]}
          pagination
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          rows={campaigns}
          columns={columns}
          loading={isLoading}
          visibleFooterSelectedRowCount
          getEstimatedRowHeight={() => 100}
          getRowHeight={() => "auto"}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          slots={{ toolbar: CustomToolbar }}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: 1,
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: 2,
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: 3,
            },
            " '&.MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={(newModel) =>
            setColumnVisibilityModel(newModel)
          }
        />
      </div>
      <UpdateCampaign
          open={open}
          setIsOpen={setOpen}
          selectedValues={values}
          setNewCampaigns={setCampaigns}
          setLoading={setIsLoading}
          agency_id={agency_id}
      />
      <UpdateCampaignCustom
          open={openCustom}
          setIsOpen={setOpenCustom}
          selectedValues={values}
          setNewCampaigns={setCampaigns}
          setLoading={setIsLoading}
          agency_id={agency_id}
      />
      <CircularProgressWithLabel
        value={progress*100}
      />
    </>
  );
};

export default ListCampaigns;
