import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import ListRecordings from "../UI/ListRecordings";
import CampaignService from "../../services/campaign.services";
import { useAuthenticator } from "@aws-amplify/ui-react";
function FindRegister() {
  const { user } = useAuthenticator((context) => [context.user]);
  let agency_id = user.attributes["custom:tpl_id"];
  const [nameDetection, setNameDetection] = useState("");
  const [startDateDetection, setStartDateDetection] = useState("");
  const [endDateDetection, setEndDateDetection] = useState("");

  let { campaign_id } = useParams();

  useEffect(() => {
    getRilevSing(campaign_id, agency_id);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const getRilevSing = (campaign_id,agency_id) => {
    CampaignService.getSingleCampaign(campaign_id,agency_id).then((data) => {
      setNameDetection(data.name);
      setStartDateDetection(data.start_date);
      setEndDateDetection(data.end_date);
    });
  };

  return (
    <Box>
      <Typography
        color="text.secondary"
        gutterBottom
        component={"span"}
        align="center"
      >
        <h2>
          {" "}
          {nameDetection}
        </h2>
          <h3>
           da: {startDateDetection} a:{" "}
          {endDateDetection}{" "}
          </h3>
          <br/>
      </Typography>


      <Typography
        color="text.secondary"
        gutterBottom
        component={"span"}
        align="center"
      >
        <h3>
        Lista rilevazioni
        </h3>
      </Typography>

      <Box>
        <ListRecordings campaignName={nameDetection}/>
      </Box>
    </Box>
  );
}

export default FindRegister;
