import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import BaseLayout from "./components/Layout/BaseLayout";
import Home from "./components/Pages/Home";
import AddRecordings from "./components/Pages/AddRecordings";
import SearchCampaigns from "./components/Pages/SearchCampaigns";
import NewCampaign from "./components/Pages/NewCampaign";
import SelectCampaign from "./components/Pages/SelectCampaign";
import ModifyRecording from "./components/Pages/ModifyRecording";
import {RouteProtect} from "./components/Layout/RouteProtect";
import UsersManagemant from "./components/Pages/UsersManagemant";
import FindRegister from "./components/Pages/FindRegister";
import ChangePassword from "./components/Auth/ChangePassword";
import NewCampaignCustom from "./components/Pages/NewCampaignCustom";
import ConfigureCustomCampaign from "./components/Pages/ConfigureCustomCampaign";
import { SelectedItemProvider } from "./SelectedContext";
import Breadcrumb from "./components/Breadcrumb";
import { ThemeProvider, createTheme } from "@mui/material";
import useSession from "./components/Auth/useSession";
import RedirectPage from "./components/Auth/RedirectPage";

const theme = createTheme({
  palette: {
    // primary: {
    //   main: '#1D2992',
    // },
    background: {
      default: '#f5f5f5'
    },
  },
});

function App() {
    // const { user } = useAuthenticator((context) => [context.user])
    // const currentAccount = user?.getSignInUserSession()?.getAccessToken().getJwtToken()
    // Token.setToken(currentAccount)
    // setInterval(() => {
    //     Token.setToken(user?.getSignInUserSession()?.getAccessToken().getJwtToken())
    // }, 800000)

    useSession();
  return (
    <ThemeProvider theme={theme}>
    <SelectedItemProvider>
    <BaseLayout>
    <Breadcrumb />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route>
          <Route
            path="/selezioneCampagna"
            element={
              <RouteProtect
                groups={[
                  "admin",
                  "adminReadOnly",
                ]}
                Component={SelectCampaign}
              />
            }
          />
          <Route
            path="/creaCampagnaOrdinaria"
            element={
              <RouteProtect
                groups={[
                  "admin",
                  "adminReadOnly",
                ]}
                Component={NewCampaign}
              />
            }
          />
          <Route
            path="/creaCampagnaCustom"
            element={
              <RouteProtect
                groups={[
                  "admin",
                  "adminReadOnly",
                ]}
                Component={NewCampaignCustom}
              />
            }
          />
          <Route
            path="/configuraCampagnaCustom/:schema"
            element={
              <RouteProtect
                groups={[
                  "admin",
                  "adminReadOnly",
                ]}
                Component={(options)=> <ConfigureCustomCampaign {...options}/>}
              />
            }
          />
          <Route
            path="/elencoCampagne"
            element={
              <RouteProtect
                groups={[
                  "admin",
                  "adminReadOnly",
                  "editorUser",
                  "adminCompany",
                ]}
                Component={SearchCampaigns}
              />
            }
          />          
          <Route
            path="/listaRilevazioni/:campaign_id"
            element={
              <RouteProtect
                groups={[
                  "admin",
                  "adminReadOnly",
                  "adminCompany"
                ]}
                Component={FindRegister}
              />
            }
          />
          <Route
            path="/caricaRilevazione/:recording_id"
            element={
              <RouteProtect
                groups={[
                  "admin",
                  "adminReadOnly",
                  "adminCompany",
                  "editorUser",
                  ]}

                Component={AddRecordings}
              />
            }
          />
          <Route
            path="/modificaRilevazione"
            element={
              <RouteProtect
                groups={[
                  "editorUser",
                  "adminCompany",
                ]}
                Component={ModifyRecording}
              />
            }
          />
          <Route
            path="/gestioneUtenze"
            element={
              <RouteProtect
                groups={[
                  "admin",
                  "adminReadOnly",
                  "adminCompany"
                ]}
                Component={UsersManagemant}
              />
            }
          />
          <Route
              path="/changePassword"
              element={<ChangePassword />}
          />
        </Route>
        <Route path="*" element={<Navigate to='/'/>} />
        <Route path="/session-expired" element={<RedirectPage />} />
      </Routes>
    </BaseLayout>
    </SelectedItemProvider>
    </ThemeProvider>
  );
}

export default App;
