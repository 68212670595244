import React from 'react';
import { AccountSettings, Button, Card, Image, View } from '@aws-amplify/ui-react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import LogoRegioneAMP_color from "../../Img/LogoRegioneAMP_color.png";
import { I18n } from 'aws-amplify';
import { translations } from '@aws-amplify/ui-react';
import { Typography } from '@mui/material';

I18n.putVocabularies(translations);
I18n.setLanguage('it');

I18n.putVocabularies({
    it: {
        "Your passwords must match": "Le password devono coincidere"

    }
})


export default function ChangePassword() {
  const navigate = useNavigate();

  const minLength = {
    validationMode: 'onChange',
    validator: (password) => password.length >= 8,
    message: 'La password deve contenere minimo 8 caratteri.',
  };
  
  const noSpaces = {
    validationMode: 'onChange',
    validator: (password) => !password.includes(' '),
    message: 'La password non deve contenere spazi vuoti.',
  };
  
  const specialChar = {
    validationMode: 'onChange',
    validator: (password) => /[!@#$%^&*,.?":|]/.test(password),
    message: 'La password deve contenere almeno un carattere speciale.',
  };
  
  const number = {
    validationMode: 'onChange',
    validator: (password) => /\d/.test(password),
    message: 'La password deve contenere almeno un numero.',
  };
  
  const upperCase = {
    validationMode: 'onChange',
    validator: (password) => /[A-Z]/.test(password),
    message: 'La password deve contenere almeno una lettera maiuscola.',
  };
  
  const lowerCase = {
    validationMode: 'onChange',
    validator: (password) => /[a-z]/.test(password),
    message: 'La password deve contenere almeno una lettera minuscola.',
  };
  
  const handleSuccess = () => {
    try {
    //   await Auth.changePassword(user, oldPassword, newPassword);
      Swal.fire({
        icon: 'success',
        title: 'Eseguito',
        html: 'Cambio password avvenuto correttamente',
        confirmButtonColor: "#3085d6",
        toast: true
      }).then(() => navigate('/'));
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        html: 'Si è verificato un problema! <br/>' + err.name,
        confirmButtonColor: "#3085d6",
        toast: true,
        footer: "Ricontrolla i dati inseriti",
      });
    }
  };

  const components  = {
    SubmitButton: (props) => <Button {...props}>Modifica password</Button>

  };

  return (
<div>
    <Card width="35vw" margin={ "0 auto"}>
            <View textAlign="center" >
              <Image alt="RegioneAMP logo" src={LogoRegioneAMP_color} />
            </View>

        <Typography
        sx={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
        color="text.secondary"
        component={"div"}
    >

      <h4>Requisiti della Password</h4>
      <p>La nuova password deve contenere minimo 8 caratteri di cui almeno un :</p>
      <ul>
        <li>carattere speciale compresi tra: ! @ # $ % ^ & * , . ? " : | </li>
        <li>numero</li>
        <li>lettera maiuscola e/o minuscola</li>
      </ul>
      </Typography>
        <AccountSettings.ChangePassword
        components={components}
        onSuccess={handleSuccess} 
        // onSuccess={(oldPassword, newPassword) => handleSuccess(oldPassword, newPassword)} 
        validators={[minLength, noSpaces, specialChar, number, upperCase, lowerCase]}
        />
    </Card>
    </div>  
    );
}



// import { useAuthenticator } from '@aws-amplify/ui-react';
// import {Auth} from 'aws-amplify'
// import DoneIcon from '@mui/icons-material/Done';
// import {Button, TextField, Typography} from "@mui/material";
// import {useState} from "react";
// import Swal from 'sweetalert2'
// import './ChangePassword.css'
// import {useNavigate} from "react-router-dom";

// /*
// Requisiti delle password:
// Lunghezza minima 8 caratteri
// Contiene almeno 1 numero
// Contiene almeno 1 carattere speciale (^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ ` + =)
// Contiene almeno 1 carattere maiuscolo
// Contiene almeno 1 carattere minuscolo
// ^$*.[]{}()?-"!@#%&,><':;|_~`+=
// */

// const ChangePassword = () => {
//     const policy = ['^', '$', '*', '.', '[', ']', '{', '}', '(', ')', '?', '-', '"', '!', '@', '#', '%', '&', '/', ',', '<', '>', "'", ':', ';', '|', '_', ' ~', '`', '+', '=' ]
//     const { user
//         // , signOut 
//     } = useAuthenticator((context) => [context.user]);
//     const [error, setError] = useState(false)
//     const [errorConfrim, setErrorConfirm] = useState(false)
//     const [oldPassword, setOldPassword] = useState('')
//     const [newPassword, setNewPassword] = useState('')
//     const navigate = useNavigate();

//     const onOldPasswordChange = (event) => {
//         setOldPassword(event.target.value)
//     }

//     const checkPolicy = (event) => {
//         //console.log(event.target.value.match(/[ $ * .  { } ( ) ?  " ! @ # % &  \ , > < ' : ; | _ ~ ` + =]/))
//         if(!(policy.some(el => event.target.value.includes(el)) && event.target.value.length>7 && event.target.value!==event.target.value.toUpperCase() && event.target.value!==event.target.value.toLowerCase() && /\d/.test(event.target.value))) {
//             setError(true)
//         }
//         else
//             setError(false)
//     }

//     const onNewPasswordChange = (event) => {
//         setNewPassword(event.target.value)
//         checkPolicy(event)
//     }

//     const onConfirmPassChange = (event) => {
//         if(event.target.value !== newPassword)
//             setErrorConfirm(true)
//         else
//             setErrorConfirm(false)
//     }

//     const submitNewPassword = async () => {

//         if (!(errorConfrim && error)) {
//             Auth.changePassword(user, oldPassword, newPassword)
//                 .then(success => Swal.fire({
//                     icon: 'success',
//                     title: 'Eseguito',
//                     html: 'Cambio password avvenuto correttamente',
//                     confirmButtonColor: "#3085d6",
//                     toast: true
//                 }).then(() => navigate('/')))
//                 .catch(err => Swal.fire({
//                     icon: 'error',
//                     title: 'Oops...',
//                     html: 'Si è verificato un problema! <br/>' + err.name ,
//                     confirmButtonColor: "#3085d6",
//                     toast: true,
//                     // JSON.stringify(err),
//                     footer: "Ricontrolla i dati inseriti",
//                 }))
//             setOldPassword('')
//             setNewPassword('')
//         }
//     }
//     //console.log('Vecchio modo',Auth.currentSession().then(data => data.getIdToken().payload['cognito:groups']))
//     //console.log('Nuovo modo', user.signInUserSession.idToken.payload['cognito:groups'])

//     return (
//         <>
        
//             <div className="containernew">
//                 <TextField
//                     id="old Password"
//                     label="Vecchia Password"
//                     variant="standard"
//                     type="password"
//                     onChange={onOldPasswordChange}
//                 />
//                 <br/>
//                 <br/>
//                 <TextField
//                     error={error}
//                     id="newPassword"
//                     label="Nuova Password"
//                     variant="standard"
//                     type="password"
//                     onChange={onNewPasswordChange}
//                 />
//                 <br/>
//                 <br/>
//                 <TextField
//                     error={errorConfrim}
//                     id="confrimnewPassword"
//                     label="Conferma Password"
//                     helperText={errorConfrim ? "Le password devono corrispondere" : null}
//                     variant="standard"
//                     type="password"
//                     onChange={onConfirmPassChange}
//                 />
//                 {!error ? ( null) :
//                     <Typography sx={{color: 'red', marginTop:2}}>
//                     La nuova password deve contenere almeno 8 caratteri di cui almeno uno speciale, un numero, una lettera maiuscola ed una minuscola
//                     </Typography>}
//                 <Button
//                     variant='contained'
//                     endIcon={<DoneIcon/>}
//                     sx={{ marginLeft: 15, marginTop: 5 }}
//                     onClick={submitNewPassword}>
//                         Applica modifica
//                     </Button>
//             </div>
//             <div>
//             </div>
//         </>
//     );
// };
// export default ChangePassword
