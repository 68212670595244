import React from "react";
import ListRecordings from "../UI/ListRecordings";


function ModifyRecording() {

  return (
  <>
  <h3>Elenco schede rilevazione</h3>
  <ListRecordings/>
  </>
  );

}

export default ModifyRecording;