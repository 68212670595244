import React, { useState, useEffect } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";

export const RouteProtect = ({ Component, ...props }) => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [autorizzato, setAutorizzato] = useState(false);

  useEffect(() => {
    // console.log("utentePuòAccedere");
    userCanAccess();
  },[user]);// eslint-disable-line react-hooks/exhaustive-deps

    const userCanAccess = () => {
    if (props.groups.length > 0) {
        let user_groups = [];
        if(user){
            user_groups = user?.getSignInUserSession()?.getAccessToken()?.payload["cognito:groups"];
            // .signInUserSession.accessToken.payload["cognito:groups"];
        }
        let intersection = props.groups.filter((role) =>
        user_groups.includes(role)
      );
      // console.log('intersection',intersection);
      if (intersection.length > 0) {
        setAutorizzato(true);
      }
    }
  };

  return (
    <>
      {autorizzato && <Component {...props} /> }
    </>
  );
};
export default RouteProtect;
