import React from "react";
import { Typography} from "@mui/material";
import LogoRegioneAMP_color from "../../Img/LogoRegioneAMP_color.png"
import Saliti_discesi from "../../Img/Saliti_discesi.png"
import { Stack } from "@mui/system";

function Home () {
  


  return (
    <>
    <Typography
      color="text.secondary"
      gutterBottom
      align="center"
      variant="h4"
    >
      
        Benvenuto nel servizio Campagna Rilevazioni Saliti e Discesi
    </Typography>
    <Stack sx={{display:"flex", alignItems:"center", mt: 8}}>
      <img alt="RegioneAMP logo" src={LogoRegioneAMP_color} style={{width:400}}/>
    </Stack>

    <Stack sx={{display:"flex", alignItems:"center", mt: 1}}>
      <img alt="Icona saliti_discesi" src={Saliti_discesi} style={{width:150,marginTop: 60}} />
    </Stack>
    
    </>
  );
}

export default Home;
