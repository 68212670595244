import React, { useEffect, useState, /* useRef, */ useCallback } from "react";
import {
  itIT,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid";
import CampaignService from "../../services/campaign.services";
import "../Style/List.css";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useParams, useLocation } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import Button from "@mui/material/Button";
import StripedDataGrid from "../Style/StripedDataGrid";
import Swal from "sweetalert2";
import CampaignServices from "../../services/campaign.services";
import Snackbar from '@mui/material/Snackbar';
import UndoIcon from '@mui/icons-material/Undo';
// import Dialog from '@mui/material/Dialog';
// import DialogTitle from '@mui/material/DialogTitle';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';

import Alert from '@mui/material/Alert';
import { Box } from "@mui/material";

import introJs from 'intro.js';
import 'intro.js/introjs.css'; // Importa gli stili di Intro.js

function computeMutation(newRow, oldRow) {
  if (newRow.prop_num_giorni <= 0 || newRow.prop_num_giorni > oldRow.num_giorni) {
    return `Giorni from '${oldRow.prop_num_giorni}' to '${newRow.prop_num_giorni}'`;
  }

  return null;
}


const FormCustomCampaignConfiguration = () => {
  const { user } = useAuthenticator((context) => [context.user]);
  const [isLoading, setIsLoading] = useState(true);
  const [trips, setTrips] = useState([]);
  // const [visible, setVisible] = useState(true);
  const [pageSize, setPageSize] = useState(15);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    actions: true,
  });
  /*const [sortModel, setSortModel] = useState([
    {
      field: 'linea',
      sort: 'asc',
    }
  ]);*/

  const sortByLineaAndFrequenza = (arr) => {
    arr.sort((a, b) => {
      return a.linea.localeCompare(b.linea) || a.frequenza.localeCompare(b.frequenza)
    })
  }

  const navigate = useNavigate();
  // let agency_id = user.attributes["custom:tpl_id"];

  let { schema } = useParams();
  const { state } = useLocation();
  const { name, start_date, end_date, project, feed, version, tripsIds, selectedCompleteStroke, campaignId } = state;

  const checkUserRole = () => {
    if (!user) return;
    const currentAccount = user?.getSignInUserSession()?.getAccessToken()
      ?.payload["cognito:groups"];
    if (currentAccount.includes("admin")) {
      // setVisible(true);
      setColumnVisibilityModel({ ...columnVisibilityModel, actions: true });
    }
    if (currentAccount.includes("adminReadOnly")) {
      // setVisible(false);
      setColumnVisibilityModel({ ...columnVisibilityModel, actions: false });
    }
  };

  // verifico se in data ci sono id uguali a quelli passati nelle prop, se si, e se presente num_giorni_proposti
  // prendo questo valore e lo sostituisco a prop_num_giorni
  const getData = (schema, start_date, end_date) => {
    CampaignServices.serviceDatesInformation(schema, start_date, end_date, tripsIds).then((data) => {
      let updatedData = data.map(item => {
        const matchingTrip = selectedCompleteStroke?.find(trip => trip.trip_id === item.trip_id);
        //console.log('Controllo trip_id:', item.trip_id, 'Trovato:', !!matchingTrip);
        if (matchingTrip && matchingTrip.num_giorni_proposti !== undefined) {
          //console.log('Sovrascrivo prop_num_giorni di trip_id:', item.trip_id, 'con:', matchingTrip.num_giorni_proposti);
          return { ...item, prop_num_giorni: matchingTrip.num_giorni_proposti };
        }
        return item;
      });
  
      // console.log('Updated Data:', updatedData);
      sortByLineaAndFrequenza(updatedData);
      setTrips(updatedData);
      setIsLoading(false);
    });
  };
    
  useEffect(() => {
    checkUserRole();
    getData(schema, start_date, end_date);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  ////////////////////////////////////////////////////

  useEffect(() => {
    const startTour = () => {

      // seleziono l'elemento con la classe defaultRilevazioni
    let targetElement = document.querySelector('.defaultRilevazioni');
    
    // se non esiste, provo con customRilevazioni
    if (!targetElement) {
      targetElement = document.querySelector('.customRilevazioni');
    }

      if (targetElement) {
        const intro = introJs();
        intro.setOptions({
          steps: [{
            element: targetElement,
            intro: 'Fare doppio click in questa colonna per modificare i valori',
          }],
          exitOnEsc: true,
          exitOnOverlayClick: true,
          showButtons: false,
          showStepNumbers: false,
        });

        intro.start();

        setTimeout(() => {
          intro.exit();
        }, 2500);
      } else {
        setTimeout(startTour, 500);
      }
    };

    startTour();
  }, []);

  const createNewCampaign = (newCampaign) => {
    return Swal.fire({
      title: "Confermi la creazione della campagna ?",
      html: "Ci vorrà del tempo, resta in attesa della creazione. <br/> Grazie",
      icon: "info",
      toast: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Confermo la creazione",
      showCancelButton: true,
      cancelButtonText: "Annulla creazione",
      cancelButtonColor: "#d33",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let loading = true;
        const loadingTimer = setTimeout(() => {
          if (loading) {
            Swal.showLoading();
          }
        }, 1000);

        CampaignService.createCampaign(newCampaign)
          .then((data) => {
            loading = false;
            clearTimeout(loadingTimer);

            Swal.fire({
              title: "Esito positivo",
              text: "Campagna creata correttamente",
              icon: "success",
              timer: 2000,
              toast: true,
              showConfirmButton: false,
              position: "center",
              background: "#DAF7A6",
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            navigate('/elencoCampagne');

            return data;
          })
          .catch((error) => {
            loading = false;
            clearTimeout(loadingTimer);

            console.log(error);

            Swal.fire({
              title: "Errore",
              text: error instanceof String ? error : 'Impossibile creare la campagna con i parametri inseriti',
              icon: "error",
              timer: 5000,
              toast: true,
              showConfirmButton: false,
              position: "center",
              background: "#F5B7B1",
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
          });
      },
    });
  };

  const updateCampaign = async (campaignId, newCampaign) => {
    document.body.style.pointerEvents = 'none';
    const loaderModal = Swal.fire({
      title: 'Modifica in corso',
      text: "Attendere che la modifica sia ultimata",
      icon: "info",
      showConfirmButton: false,
      toast: true,
      didClose: () => {
        document.body.style.pointerEvents = 'auto';
      }
    });
  
    try {
      const response = await CampaignService.updateCampaign(campaignId, newCampaign);
      loaderModal.close();
  
      if ('error' in response) {
        Swal.fire({
          title: 'Errore',
          text: response.error instanceof String ? response.error : 'Impossibile modificare la campagna con i parametri inseriti',
          icon: 'error',
          timer: 5000,
          toast: true,
          showConfirmButton: false,
          position: 'center',
          background: '#F5B7B1',
        });
      } else {
        Swal.fire({
          title: "Esito positivo",
          text: "Campagna modificata correttamente",
          icon: "success",
          toast: true,
          showConfirmButton: false,
          position: "center",
          background: "#DAF7A6",
          timer: 2000,
          timerProgressBar: true,
          // didOpen: (toast) => {
          //   toast.addEventListener("mouseenter", Swal.stopTimer);
          //   toast.addEventListener("mouseleave", Swal.resumeTimer);
          // },
        }).then(() => {
          navigate('/elencoCampagne');
        });
      }
    } catch (error) {
      console.error('Errore durante la gestione della richiesta:', error);
      Swal.fire({
        title: 'Errore imprevisto',
        text: 'Si è verificato un errore imprevisto durante la modifica della campagna.',
        icon: 'error',
        toast: true,
        showConfirmButton: true,
        position: 'center',
        background: '#F5B7B1',
      });
    } finally {
      document.body.style.pointerEvents = 'auto';
    }
  }  

  function EditableCell(props) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '24px', margin: '0 auto' }}>
        <DriveFileRenameOutlineIcon fontSize="small" style={{ color: '#C0C0C0' }} />
        {props.value}
      </div>
    );
  }

  const columns = [
    {
      field: "trip_id",
      headerName: "Trip_ID",
      flex: 1,
    },
    {
      field: "agency_name",
      headerName: "Azienda",
      flex: 1,
    },
    {
      field: "linea",
      headerName: "Linea",
      flex: 2,
    },
    {
      field: "percorso",
      headerName: "Percorso",
      flex: 0.6,
    },
    {
      field: "percorso_da",
      headerName: "Percorso da:",
      flex: 1,
    },
    {
      field: "percorso_a",
      headerName: "Percorso a:",
      flex: 1,
    },
    {
      field: "corsa",
      headerName: "Corsa",
      flex: 0.6,
    },
    {
      field: "frequenza",
      headerName: "Frequenza",
      flex: 0.8,
    },
    {
      field: "num_giorni",
      headerName: "Numero giorni di servizio",
      // type: 'number',
      flex: 0.8,
    },
    {
      field: "prop_num_giorni",
      headerName: "Proposta rilevazioni",
      flex: 0.8,
      type: 'number',
      headerAlign: 'left',
      editable: true,
      renderCell: EditableCell
    },

  ];

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    defaultValues: {
      name,
      start_date,
      end_date,
      select_project: [project],
      select_feed: [feed],
      select_version: [version],
    }
  });

  const [startDate, endDate] = useWatch({
    control,
    name: ["start_date", "end_date", "select_project", "select_feed", "select_version"]
  });

  const onSubmit = (data) => {
    const { name, start_date, end_date } = data;
    const feedVersions = [{ schema, project, feed, version }];
    const newCampaign = {
      name,
      start_date,
      end_date,
      feedVersions,
      trips: trips.map(t => { return { trip_id: t.trip_id, rilevazioni: t.prop_num_giorni } })
    };
    // createNewCampaign(newCampaign);
    if (selectedCompleteStroke && selectedCompleteStroke.length > 0) {
      updateCampaign(campaignId, newCampaign);
    } else {
      createNewCampaign(newCampaign);
    }  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  //// UNDO edit

  // const noButtonRef = useRef(null);
  const [promiseArguments, setPromiseArguments] = useState(null);

  const [snackbar, setSnackbar] = useState(null);

  const handleCloseSnackbar = () => setSnackbar(null);

  const processRowUpdate = useCallback(
    (newRow, oldRow) =>
      new Promise((resolve, reject) => {
        const mutation = computeMutation(newRow, oldRow);
        if (mutation) {
          // Salvo i parametri per futura promise
          setPromiseArguments({ resolve, reject, newRow, oldRow });
        } else {
          //aggiorno la trip con il numero di rilevazioni proposte
          const trips_to_be_updated = trips.filter(t => t.linea === newRow.linea &&
            t.frequenza === newRow.frequenza &&
            (t.num_giorni === t.prop_num_giorni || t.trip_id === oldRow.trip_id)
          )
          const updated_trips_for_linea = trips_to_be_updated.map((el) => {
            return {
              ...el,
              prop_num_giorni: newRow.prop_num_giorni,
            }
          }
          )
          const other_trips = trips.filter(t => t.linea !== newRow.linea || t.frequenza !== newRow.frequenza || (t.linea === newRow.linea && t.num_giorni !== t.prop_num_giorni && t.trip_id !== oldRow.trip_id))
          const patchedTrips = (other_trips.concat(updated_trips_for_linea))
          sortByLineaAndFrequenza(patchedTrips)
          setTrips(patchedTrips)
          resolve(newRow); //applico il nuovo valore, non è superiore al numero di giorni di servizio
        }
      }),
    [trips],
  );

  const showConfirmDialog = () => {
    if (!promiseArguments) {
      return;
    }

    const { oldRow, resolve } = promiseArguments;

    Swal.fire({
      title: "Attenzione!",
      text: "Il numero di rilevazioni non può essere nullo o superiore al numero di giorni di servizio",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Ok",
      //allowOutsideClick: false,
      allowEscapeKey: false,
      //allowEnterKey: false
    }).then((result) => {
      if (result.isConfirmed) {
        resolve(oldRow);
        setPromiseArguments(null);
      }
    });
  };


  ////

  // const coloriPerValore = ['#ffadad', '#ffd6a5', '#fdffb6', '#caffbf', '#9bf6ff', '#a0c4ff', '#bdb2ff', '#ffc6ff', '#fffffc', '#ffafcc'];
  // const coloriPerValore = {0:'#ffadad', 1:'#ffd6a5', 2:'#fdffb6', 3:'#caffbf', 4:'#9bf6ff', 5:'#a0c4ff', 6:'#bdb2ff', 7:'#ffc6ff', 8:'#fffffc', 9:'#ffafcc'};


  return (
    <>

      <h3 style={{ textAlign: 'center', fontStyle: 'italic', color: '#495057' }}>{project} - {feed} - {version}</h3>
      <form onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "grid",
          alignItems: "baseline",
          gridRowGap: "20px",
        }}
      >
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
          <Button variant="outlined" startIcon={< UndoIcon />} onClick={() => navigate(-1)} >
            Torna indietro
          </Button>

          <Button
            variant="contained"
            type="submit"
            disabled={!isValid || startDate > endDate || startDate === endDate}
          >
            {selectedCompleteStroke && selectedCompleteStroke.length > 0 ? "Completa modifica" : "Crea campagna"}
          </Button>
        </Box>
        <div style={{ height: 700, width: "100%" }}>
          {/* {renderConfirmDialog()} */}
          {showConfirmDialog()}
          <StripedDataGrid
            experimentalFeatures={{ newEditingApi: true }}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            processRowUpdate={processRowUpdate}
            rowsPerPageOptions={[15, 25, 35, 45]}
            pagination
            localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
            rows={trips}
            columns={columns}
            loading={isLoading}
            //sortModel={sortModel}
            //onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
            hideFooterSelectedRowCount
            getEstimatedRowHeight={() => 100}
            getRowId={(row) => row.trip_id}
            getRowHeight={() => "auto"}
            slots={{ toolbar: CustomToolbar }}
            ///// PAOLO //////
            getCellClassName={(params) => {
              if (params.row['prop_num_giorni'] < params.row['num_giorni'] && params.field === 'prop_num_giorni') {
                return 'customRilevazioni'
              } else if (params.field === 'prop_num_giorni')
                return 'defaultRilevazioni';
            }
            }
            /////////////////
            //// TOMMASO ////////
            // getCellClassName={(params) => {
            //   if (params.field === 'prop_num_giorni') {
            //     const value = params.row['prop_num_giorni'];
            //     return `customCell-${value}`; // Assegna una classe basata sul valore
            //   }
            //   return '';
            // }}
            ///////////////
            sx={{
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: 1,
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: 2,
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: 3,
              },
              ".customRilevazioni": {
                backgroundColor: '#F1F941BB',
              },
              ".defaultRilevazioni": {
                backgroundColor: '#8FED45BB',
              }
              ///////// SCALA COLORI /////////
              // ...Array.from({ length: 10 }, (_, i) => i + 1).reduce((acc, val) => {
              //   acc[`.customCell-${val}`] = {
              //     backgroundColor: `rgba(255, ${255 - val * 20}, 0, 0.7)`, // Esempio di colore dinamico
              //   };
              //   return acc;
              // }, {}),
              ///////////////////////////////
              ///////// ARRAY COLORI  ////////
              // ...coloriPerValore.reduce((acc, color, index) => {
              //   acc[`.customCell-${index + 1}`] = { backgroundColor: color };
              //   return acc;
              // }, {}),
              //////////////////////////////
              /////// OGGETTO COLORI ///////
              // ...Object.entries(coloriPerValore).reduce((acc, [value, color]) => {
              //   acc[`.customCell-${value}`] = { backgroundColor: color };
              //   return acc;
              // }, {}),
              //////////////////////////////
            }}
            initialState={{
              columns: {
                columnVisibilityModel: {
                  ...columnVisibilityModel,
                  percorso_da: false,
                  percorso_a: false,
                },
              },
            }}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
          />
          {!!snackbar && (
            <Snackbar open onClose={handleCloseSnackbar} autoHideDuration={6000}>
              <Alert {...snackbar} onClose={handleCloseSnackbar} />
            </Snackbar>
          )}
        </div>
      </form>
    </>
  );
}

export default FormCustomCampaignConfiguration;

