import React from "react";
import { Box, Typography } from "@mui/material";
import FormCustomCampaignConfiguration from "../UI/FormCustomCampaignConfiguration";

function ConfigureCustomCampaign() {
  return (
    <Box>
      <Typography
        color="text.secondary"
        gutterBottom
        component={"span"}
        align="center"
      >
        <h2> Configurazione Campagna Rilevazione "Custom"</h2>
      </Typography>
      <Box>
        <FormCustomCampaignConfiguration />
      </Box>
    </Box>
  );
}

export default ConfigureCustomCampaign;
