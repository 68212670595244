import React from "react";
import Typography from "@mui/material/Typography";
import RecordingsGrid from "../UI/Recording";
import {Box, Button} from "@mui/material";
import UndoIcon from '@mui/icons-material/Undo';
import {useNavigate} from "react-router-dom";
function AddRecordings() {
    const navigate = useNavigate();

  return (
    <>
        <Button variant="outlined" startIcon={< UndoIcon/>} onClick={() => navigate(-1)} >
            Torna alla lista rilevazioni
        </Button>
      <Typography
        color="text.secondary"
        gutterBottom
        component={"span"}
        align="center"
      >
        <h3> Carica i tuoi file </h3>
      </Typography>
      <Typography
        color="text.secondary"
        gutterBottom
        component={"span"}
        align="center"
      >
        <h3>Compila il form con i dati delle schede cartacee </h3>
      </Typography>
      <Box >
        <RecordingsGrid />
      </Box>
      <br />
    </>
  );
}

export default AddRecordings;
