import React, { useState, useEffect } from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button,
  Box,
  Chip,
} from "@mui/material";
import "../Style/FormStyle.css";
import Swal from "sweetalert2";
import "animate.css";
import CampaignService from "../../services/campaign.services";
import { format } from "date-fns";
import {it} from 'date-fns/locale'
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: "8px",
    },
  },
};

function FormCampaign() {
  const [projects, setProjects] = useState([]); 
  const [feeds, setFeeds] = useState([]);
  const [versions, setVersions] = useState([]);
  const [isTextFieldFilled, setIsTextFieldFilled] = useState(false);
  const [isDateFilled, setIsDateFilled] = useState(false);
  const [schemas, setSchemas] = useState([]);
  const navigate = useNavigate();


  const getListProjects = () => {
    CampaignService.getProjects().then((data) => {
      setProjects(data);
    });
  };

  const getListFeeds = () => {
    CampaignService.getFeeds().then((data) => {
      setFeeds(data);
    });
  };

  const getVersions = () => {
    CampaignService.getVersions().then((data) => {
      setVersions(data);
    });
  };

  const createNewCampaign = (newCampaign) => {
    return Swal.fire({
      title: "Confermi la creazione della campagna?",
      html: "Ci vorrà del tempo, resta in attesa della creazione. <br/> Grazie",
      icon: "info",
      toast: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: "Confermo la creazione",
      showCancelButton: true,
      cancelButtonText: "Annulla creazione",
      cancelButtonColor: "#d33",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let loading = true;
        const loadingTimer = setTimeout(() => {
          if (loading) {
            Swal.showLoading();
          }
        }, 1000);
  
        CampaignService.createCampaign(newCampaign)
          .then((data) => {
            loading = false;
            clearTimeout(loadingTimer);
  
            Swal.fire({
              title: "Esito positivo",
              text: "Campagna creata correttamente",
              icon: "success",
              timer: 2000,
              toast: true,
              showConfirmButton: false,
              position: "center",
              background: "#DAF7A6",
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
    
            navigate('/elencoCampagne');
    
            return data;
          })
          .catch((error) => {
            loading = false;
            clearTimeout(loadingTimer);
            if (typeof error =='object')
              error = error.error
            
            let errorMessage = 'Impossibile creare la campagna con i parametri inseriti '
            errorMessage+=error.name=='SequelizeUniqueConstraintError'?'(una campagna con lo stesso nome esiste già)':''
            Swal.fire({
              title: "Errore",
              html:  typeof error=='string' ? error : errorMessage,
              icon: "error",
              timer: 5000,
              toast: true,
              showConfirmButton: false,
              position: "center",
              background: "#F5B7B1",
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
          });
      },
    });
  };
      

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setError,
    clearErrors,
    setValue
  } = useForm({ defaultValues: {
    name: "",
    start_date: null,
    end_date: null,
    select_project: [],
    select_feed: [],
    select_version: [],
  } 
  });

  const [startDate, endDate, selected_project, selected_feed, selected_version] = useWatch({
    control,
    name: ["start_date", "end_date", "select_project", "select_feed", "select_version"],
  });

  useEffect(() => {
    getListProjects();
  }, []);

  useEffect(() => {
    if (selected_project) {
      getListFeeds();
    }
  }, [selected_project]);

  useEffect(() => {
    if (selected_feed) {
      getVersions();
    }
  }, [selected_feed]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (startDate !== null && endDate !== null) {
      setIsDateFilled(true);
    } else {
      setIsDateFilled(false);
    }
  }, [startDate, endDate]);

  const onSubmit = (data) => {
    const { name, start_date, end_date } = data;
    const feedVersions = schemas;
    const startDate = start_date ? format(start_date,"yyyy-MM-dd") : null;
    const endDate = end_date ? format(end_date,"yyyy-MM-dd") : null;
    const newCampaign = {
      name,
      start_date: startDate,
      end_date: endDate,
      feedVersions,
    };
    console.log('new campaign', newCampaign)
    createNewCampaign(newCampaign);
  }; 
  
  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "grid",
          alignItems: "baseline",
          gridRowGap: "20px",
        }}
      >
        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              sx={{ display: "grid" }}
              id="name_campaign"
              inputProps={{ maxLength: 50 }}
              label="Nome campagna"
              variant="standard"
              value={value}
              onChange={(e) => {
                onChange(e);
                setIsTextFieldFilled(e.target.value !== "");
              }}
              // // FUNZIONA -- Aggiungo suffisso al nome campagna
              // onBlur={(e) => {
              //   // qui aggiungo il suffisso al valore di input quando il campo perde il focus
              //   let suffixedValue = e.target.value + ' -CUSTOM';
                
              //   // lo passo alla funzione onChange
              //   onChange(suffixedValue);
              // }}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: "Inserisci un nome" }}
        />

  {isTextFieldFilled ? (
          <>
            <Controller
              name="start_date"
              rules={{ required: "Data obbligatoria" }}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                  <DatePicker
                    label="Data Inizio"
                    inputFormat="dd/MM/yyyy"
                    //disablePast={true}
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      // if (newValue > endDate) {
                      //   setError("end", {
                      //     type: "custom",
                      //     message: "Data non corretta",
                      //   });
                      // } else {
                      //   clearErrors("end");
                      // }
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "grid" }}
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }} // evitare l'inserimento dattiloscritto della data
                        id="start_date"
                        variant="standard"
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </LocalizationProvider>
              )
            }
            />
            <Controller
              name="end_date"
              rules={{ required: "Data obbligatoria", deps: ["start_date"] }}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                  <DatePicker
                    minDate={startDate}
                    label="Data Fine"
                    //disablePast={true}
                    sx={{ display: "none" }}
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={(newValue) => {
                      onChange(newValue);
                      // if (newValue >= startDate) {
                      //   clearErrors("end");
                      // } else {
                      //   setError("end", {
                      //     type: "custom",
                      //     message: "Data non corretta",
                      //   });
                      // }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          sx={{ display: "grid" }}
                          {...params}
                          inputProps={{ ...params.inputProps, readOnly: true }} // evitare l'inserimento dattiloscritto della data
                          id="end_date"
                          variant="standard"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              )}
            />
          </>
        ) : null}

  {isDateFilled ? (
          <Controller
            name="select_project"
            control={control}
            rules={{ required: "Selezione obbligatoria" }}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ display: "grid" }}>
                <InputLabel
                  id="mutiple-select-project"
                  sx={{ display: "grid" }}
                >
                  Seleziona Progetto
                </InputLabel>
                <Select
                  labelId="mutiple-select-project"
                  multiple
                  variant="standard"
                  value={value}
                  onChange={(event) => {
                    let project_S = event.target.value;
                    // if (project_S.includes("_all")) {
                    //   if (project_S.length > projects.length) {
                    //     project_S = [];
                    //   } else {
                    //     project_S = projects.map((p) => p.name);
                    //   }
                    // }                    
                    const filtered_feeds = feeds
                      .filter((feed) => project_S.includes(feed.projectId.name))
                      .map((feed) => feed.name);

                      setValue('select_feed', selected_feed.filter((feed) => filtered_feeds.includes(feed)));

                    const filtered_versions = versions
                      .filter((version) => filtered_feeds.includes(version.feedSourceId?.name))
                      .map((version) => version._id);
                      setValue('select_version', selected_version.filter((version) => filtered_versions.includes(version)));

                  onChange(project_S);
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                  >
                    <MenuItem disabled value=''>
                      <em>Progetti</em>
                    </MenuItem>
                  {projects.map((option,index) => (
                    <MenuItem key={index} value={option.name}>
                      <ListItemIcon>
                        <Checkbox
                          value={option.name}
                          checked={value.includes(option.name)}
                        />
                      </ListItemIcon>
                      <ListItemText primary={option.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        ) : null}

  {selected_project.length > 0 ? (
          <Controller
            name="select_feed"
            control={control}
            rules={{ required: "Selezione obbligatoria" }}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ display: "grid" }}>
                <InputLabel id="mutiple-select-feed" sx={{ display: "grid" }}>
                  Seleziona Feed
                </InputLabel>
                <Select
                  labelId="mutiple-select-feed"
                  multiple
                  variant="standard"
                  value={value}
                  onChange={(event) => {
                    let feed_S = event.target.value;
                    // if (feed_S.includes("_all")) {
                    //   if (feed_S.length > feeds.length) {
                    //     feed_S = [];
                    //   } else {
                    //     feed_S = feeds
                    //       .filter((feed) =>
                    //         selected_project.includes(feed.projectId.name)
                    //       )
                    //       .map((feed) => feed.name);
                    //   }
                    // }
                    let filtered_versions = []
                    filtered_versions = versions
                        .filter((version) =>
                            feed_S.includes(version.feedSourceId?.name)
                        )
                        .map((version) => version._id);
                    const new_versions =  selected_version.filter((version) => filtered_versions.includes(version))
                    setValue('select_version', new_versions)
                    const schema_S = versions.filter((v) => 
                                                new_versions.includes(v._id) && selected_feed.includes(v.feedSourceId.name)).map((v) => 
                                                  {return { 
                                                    version: v.name,
                                                    schema: v.namespace, 
                                                    feed: v.feedSourceId?.name, 
                                                    project: v.feedSourceId.projectId?.name
                                                  }});
                    setSchemas(schema_S);
                    return onChange(feed_S);
                  }}
                  renderValue={(selected) => {
                    return (
                        <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                          {selected.map((value) => (
                              <Chip key={value} label={value}/>
                          ))}
                        </Box>
                    )
                  }}
                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value=''>
                      <em>Feed</em>
                    </MenuItem>
                  {feeds
                    .filter((feed) =>
                      selected_project.includes(feed.projectId.name)
                    )
                    .map((option,index) => (
                      <MenuItem key={index} value={option.name}>
                        <ListItemIcon>
                          <Checkbox
                            value={option.name}
                            checked={value.includes(option.name)}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={option.name}
                          secondary={option.projectId.name}
                        />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            )}
            />
            ) : null}

  {selected_feed.length > 0 ? (
          <Controller
            name="select_version"
            control={control}
            rules={{ required: "Selezione obbligatoria" }}
            render={({ field: { onChange, value } }) => (
              <FormControl sx={{ display: "grid" }}>
                <InputLabel
                  id="mutiple-select-version"
                  sx={{ display: "grid" }}
                >
                  Seleziona Versione
                </InputLabel>
                <Select
                  labelId="select-version"
                  multiple
                  variant="standard"
                  value={value}
                  onChange={(event) => {
                    const version_S = event.target.value;
                     // ultimo elemento selezionato
                    const lastSelectedVersion = version_S[version_S.length - 1]; 
                  
                    const updatedSelectedVersions = version_S.filter(selectedVersion => {
                    // per ogni versione selezionata, controllo se appartiene allo stesso feed della nuova selezione
                    const versionDetail = versions.find(v => v._id === selectedVersion);
                    const lastVersionDetail = versions.find(v => v._id === lastSelectedVersion);
                
                    // conservo la versione selezionata SOLO SE NON appartiene allo stesso feed della nuova selezione o è la nuova selezione è la stessa
                    return versionDetail.feedSourceId.name !== lastVersionDetail.feedSourceId.name || selectedVersion === lastSelectedVersion;
                    });
                  
                    const schema_S = versions.filter(v => updatedSelectedVersions.includes(v._id)).map(v => ({
                      version: v.name, 
                      schema: v.namespace, 
                      feed: v.feedSourceId?.name, 
                      project: v.feedSourceId.projectId?.name 
                    }));
                  
                    setSchemas(schema_S);
                    return onChange(updatedSelectedVersions);
                  }}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => {
                        let val = versions.find(v => v._id === value)
                        return(<Chip key={val._id} label={val.name}/>)
                      })}
                    </Box>
                  )}

                  MenuProps={MenuProps}
                >
                  <MenuItem disabled value=''>
                      <em>Versioni</em>
                  </MenuItem>

                  {versions
                    .filter((version) => selected_feed.includes(version.feedSourceId?.name))
                    .map((option, index) => {
                      return (
                          <MenuItem key={index} value={option._id}>
                              <ListItemIcon>
                                <Checkbox
                                  value={option.name}
                                  checked={value.includes(option._id)}
                                />
                              </ListItemIcon>
                              <ListItemText
                                  primary={option.name}
                                  secondary={option.feedSourceId?.name}
                              />
                          </MenuItem>
                      )
                    })}
                </Select>
              </FormControl>
            )}
          />
        ) : null}

        <br />
        <Button
          sx={{width:250, m:'auto'}}
          variant="contained"
          type="submit"
          disabled={!isValid || startDate > endDate || startDate === endDate}
        >
          Crea campagna ordinaria
        </Button>
      </form>
    </>
  );
}

export default FormCampaign;
