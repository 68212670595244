import React, { createContext, useContext, useState } from 'react';

const SelectedItemContext = createContext();

export const useSelectedItem = () => useContext(SelectedItemContext);

export const SelectedItemProvider = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const setSelected = (itemKey) => {
    setSelectedItem(itemKey);
  };

  return (
    <SelectedItemContext.Provider value={{ selected: selectedItem, setSelected }}>
      {children}
    </SelectedItemContext.Provider>
  );
};
