import {API, Auth} from "aws-amplify";

class CognitoService {
    static async listAllUsers() {
        let apiName = "AdminQueries";
        let path = "/listUsers";
        let myInit = {
            queryStringParameters: {
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
        };
        const {NextToken, ...rest} = await API.get(apiName, path, myInit);
        return rest.Users
    }

    static async listUsersInGroup(groupName) {
        let apiName = "AdminQueries";
        let path = "/listUsersInGroup";
        let myInit = {
            queryStringParameters: {
                groupname: groupName
            },
            headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
        };
        const {NextToken, ...rest} = await API.get(apiName, path, myInit);
        return rest.Users
    }

    static async disableUser(userName) {
        let apiName = "AdminQueries";
        let path = "/disableUser";
        let myInit = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
            body: {
                username: userName
            }
        };
        return await API.post(apiName, path, myInit)
    }

    static async deleteUser(userName) {
        let apiName = "AdminQueries";
        let path = "/deleteUser";
        let myInit = {
            headers: {
                "Content-Type": "application/json",
                Authorization: `${(await Auth.currentSession())
                    .getAccessToken()
                    .getJwtToken()}`,
            },
            body: {
                username: userName
            }
        };
        return await API.del(apiName, path, myInit)
    }

}
export default CognitoService