import React, { useState, useEffect } from "react";
import { Auth, API } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";

import {
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Checkbox,
  ListItemText,
  Button,
  FormHelperText,
  Box,
  InputAdornment,
  IconButton,
} from "@mui/material";
import UsersService from "../../services/users.services";
import Swal from "sweetalert2";
import { GroupMapping } from "../../utils";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      maxWidth: "none",
      // width: 250,
      borderRadius: "8px",
    },
  },
};

/// nomi parlanti
function CreateUserForm(props) {
  const { user } = useAuthenticator((context) => [context.user]);

  const [companies, setCompanies] = useState({});
  // console.log(
  //   "🚀 ~ file: FormUser.js:38 ~ CreateUserForm ~ companies:",
  //   companies
  // );
  const [list_Groups, setList_Groups] = useState({});
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [valueTpl, setValueTpl] = useState([]);
  const [valueGroup, setValueGroup] = useState([]);
  const [restrictedGroups, setRestrictedGroups] = useState([]);

  let agency_id = user.attributes["custom:tpl_id"];

  const checkUserRole = () => {
    if (!user) return [];
    const currentAccount = user?.getSignInUserSession()?.getAccessToken()
      ?.payload["cognito:groups"];

    if (currentAccount.includes("adminReadOnly")) {
      return ["admin", "adminReadOnly", "adminCompany"];
    } else if (currentAccount.includes("adminCompany")) {
      return ["admin", "adminReadOnly"];
    } else if (currentAccount.includes("admin")) {
      return ["admin"];
    }
    return [];
  };

  useEffect(() => {
    setRestrictedGroups(checkUserRole());
    allCompanies(agency_id);
    listGroups();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const allCompanies = (agency_id) => {
    UsersService.getCompanies(agency_id).then((data) => {
      let companiesMap = {};
      data.forEach((company) => {
        companiesMap[company.agency_id] = company.agency_name;
      });
      setCompanies(companiesMap);
      // console.log(companiesMap);
    });
  };

  let nextToken;
  async function listGroups() {
    let apiName = "AdminQueries";
    let path = "/listGroups";
    let myInit = {
      queryStringParameters: {
        token: nextToken,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    const { NextToken, ...rest } = await API.get(apiName, path, myInit);
    nextToken = NextToken;
    setList_Groups(rest);
  }

  const groupOptions = [];
  list_Groups?.Groups?.forEach((group) => {
    groupOptions.push({
      value: group.GroupName,
    });
  });
  // console.log(groupOptions);

  async function addToGroup(username, group) {
    let apiName = "AdminQueries";
    let path = "/addUserToGroup";
    let myInit = {
      body: {
        username,
        groupname: group,
      },
      headers: {
        "Content-Type": "application/json",
        Authorization: `${(await Auth.currentSession())
          .getAccessToken()
          .getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, myInit);
  }

  async function handleSubmit(event) {
    event.preventDefault();
    console.log(valueGroup[0]);
    try {
      // Recupera il nome utente
      let username = email;
      if (username === undefined || !username.includes("@"))
        throw new Error("L' email inserita non è valida");
      // Recupera l'ID dell'azienda
      let tplId = valueTpl.join(";");
      if (tplId === "" && valueGroup[0] !== "adminReadOnly")
        throw new Error("Inserire un'agenzia per l'utente creato");
      if (tplId === "" && valueGroup[0] === "adminReadOnly") {
        tplId = "0";
      }
      // Recupera il nome del gruppo
      let group = valueGroup[0];

      if (group === undefined)
        throw new Error("specificare un gruppo per l'utente");

      // eslint-disable-next-line no-unused-vars
      const signUpResponse = await Auth.signUp({
        username,
        password,
        attributes: {
          "custom:tpl_id": tplId,
        },
        autoSignIn: {
          enabled: true,
        },
      });

      // eslint-disable-next-line no-unused-vars
      const addGroup = await addToGroup(username, group);
      if (["adminCompany", "adminReadOnly"].includes(group))
        await addToGroup(username, "admin");
      setEmail("");
      setPassword("");
      setValueTpl([]);
      setValueGroup([]);
      Swal.fire({
        title: "Utenza creata con successo",
        text: "La nuova utenza è stata creata con successo",
        icon: "success",
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6",
        toast: true,
      });
      props.onSubmit();
    } catch (error) {
      console.log("sono dentro il catch", error);
      Swal.fire({
        title: "Si è verificato un errore durante la creazione dell'utenza",
        text: error,
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6",
        toast: true,
      });
    }
  }

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleTplChange = (event) => {
    setValueTpl(event.target.value);
  };

  const handleGroupChange = (event) => {
    setValueGroup(event.target.value);
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
  setShowPassword(!showPassword);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2} alignItems="flex-start" justifyContent="center">
        <Grid item>
        <Box height={70}>
          <Box sx={{ display: "grid" }}>
            <TextField
              sx={{ width: 300 }}
              label="Email"
              type="email"
              autoComplete="off"
              variant="standard"
              value={email}
              onChange={handleEmailChange}
              margin="normal"
              // fullWidth
            />
          </Box>
            <FormHelperText error>{email.length === 0 && " "}</FormHelperText>
          </Box>
        </Grid>
        <Grid item>
            <Box  height={75} sx={{ display: 'flex', flexDirection:"column"}}>
              <Box height={70}>
              <TextField
                sx={{ width: 250 }}
                label='Password'
                autoComplete='new-password'
                variant='standard'
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={handlePasswordChange}
                margin='normal'
                // fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='toggle password visibility'
                        onClick={handleClickShowPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
              <FormHelperText error>
                {password.length < 8 || !/[A-Z]/.test(password) || !/\d/.test(password) ? (
                  <>
                    Obbligatori 8 caratteri, un carattere maiuscolo,
                    <br />
                    un carattere speciale e almeno un numero
                  </>
                ) : (
                  ' '
                )}
              </FormHelperText>
            </Box>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
        <Box height={60}>
          <FormControl>
            <InputLabel id="demo-multiple-checkbox-label">Azienda</InputLabel>
            <Select
              sx={{ width: 300 }}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              variant="standard"
              multiple
              value={valueTpl}
              onChange={handleTplChange}
              MenuProps={MenuProps}
              renderValue={(selected) =>
                selected.map((key) => companies[key]).join(", ")
              }
            >
              {Object.values(companies)
                .sort()
                .map((val) => {
                  const key = Object.keys(companies).find(
                    (key) => companies[key] === val
                  );
                  return (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={valueTpl.indexOf(key) > -1} />
                      <ListItemText primary={val} />
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </Box>
        </Grid>
        <Grid item sx={{ mt: 2 }}>
        <Box height={60}>
          <FormControl>
            <InputLabel id="demo-multiple-checkbox-label">Ruolo</InputLabel>
            <Select
              sx={{ width: 200 }}
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              variant="standard"
              multiple
              value={valueGroup}
              onChange={handleGroupChange}
              MenuProps={MenuProps}
              renderValue={(selected) => selected.join(", ")}
            >
              {groupOptions
                .filter((option) => !restrictedGroups.includes(option.value))
                .map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox checked={valueGroup.indexOf(option.value) > -1} />
                    <ListItemText primary={GroupMapping[option.value]} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Box>
        </Grid>
        <Button sx={{ ml: 5, mt: 5.5 }} type="submit" variant="outlined" startIcon={<CheckIcon />}>
          Conferma
        </Button>
      </Grid>
    </form>
  );
}

export default CreateUserForm;
