import updateHeaders from "../utils"
import FileSaver from "file-saver";


class CampaignService {

  getAllCampaigns = (agency_id) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + '/api/campaigns/?agency_id=' + agency_id ;
    return fetch(fetchUrl, updateHeaders()).then(res => res.status === 200 ? res.json() : []).then(json => { return json})
  };

  getSingleCampaign = (campaign_id,agency_id) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/campaigns/" + campaign_id + "?agency_id=" + agency_id;
    return fetch(fetchUrl, updateHeaders()).then((res) =>
        res.status === 200 ? res.json() : {}
    ).then((json) => {return json});
  };

  deleteSingleCampaign = (campaign_id, agency_id) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/campaigns/" + campaign_id;
    return fetch(fetchUrl, updateHeaders({
      method: "DELETE"
    })).then((res) =>
       res.json()
    )
    .catch((error) => {
      console.error(error);
      alert("Si è verificato un errore durante l'eliminazione della campagna");
    });
  }

  getProjects = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/projects" ;
    return fetch(fetchUrl, updateHeaders()).then((res) => res.json()).then((json) => {return json});
  };

  getFeeds = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/feeds";
    return fetch(fetchUrl, updateHeaders()).then((res) => res.json()).then((json) => {return json});
  };

  getVersions = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/feed-versions";
    return fetch(fetchUrl, updateHeaders()).then((res) => res.json()).then((json) => {return json});
  };

  getTrips = (version) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + `/api/routes/${version}`;
    return fetch(fetchUrl, updateHeaders()).then((res) => res.json()).then((json) => {return json});
  };

  getStroke = (version) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + `/api/routes/trips/${version}`;
    return fetch(fetchUrl, updateHeaders()).then((res) => res.json()).then((json) => {return json});
  };

  getTripsandStroke = (version, campaignId) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + `/api/routes/campaign/${version}?campaign_id=${campaignId}`;
    return fetch(fetchUrl, updateHeaders())
        .then(res => res.json())
        .then(json => json);
};


  getFlags = (campaign_id) => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/campaigns/toggle-status/" + campaign_id;
    return fetch(fetchUrl, updateHeaders()).then((res) => res.json()).then((json) => {return json}); 
  }


  createCampaign = (newCampaign) => {
    return new Promise((resolve, reject) => {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const fetchUrl = baseUrl + "/api/campaigns/create";
      fetch(fetchUrl, updateHeaders({
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newCampaign),
      }))
        .then(async (response) => {
          if (!response.ok) {
            const error = await response.json()
            throw error
          }
          return response.json();
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  serviceDatesInformation = (schema, start_date, end_date, tripsIds) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(tripsIds)
    }
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const fetchUrl = baseUrl + "/api/service-dates/" + schema + "?start_date="+start_date+"&end_date="+end_date
    return fetch(fetchUrl, updateHeaders(requestOptions)).then(res => res.status === 200 ? res.json() : []).then(json => { return json})
  };

  downloadZip = (paths, campaign_id, campaign_name) => {
    return new Promise((resolve, reject) => {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const fetchUrl = baseUrl + "/free_api/campaigns/download_zip/" + campaign_id + "?paths=" + encodeURIComponent(paths)
      fetch(fetchUrl, updateHeaders())
          .then((res) => res.blob())
          .then((blob) => {
            FileSaver.saveAs(blob, `campagna_${campaign_name}.zip`);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
    });
  };

  downloadPdf = (agency_id, campaign_id) => {
    return new Promise((resolve, reject) => {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const fetchUrl = baseUrl + '/free_api/campaigns/download_pdf/' + campaign_id + "?agency_id=" + agency_id
      fetch(fetchUrl, updateHeaders())
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
    });
  };

    updateCampaign = (id, campaign) => {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const fetchUrl = baseUrl + "/api/campaigns/" + id;
      return fetch(fetchUrl, updateHeaders({
        method: "PUT",
            headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(campaign),
      })).then(data => data.json()).catch(err => err.json())
    }

    downloadCsv = (campaign_id, campaign_name,agency_id) => {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const fetchUrl = baseUrl + "/api/campaigns/export_csv/" + campaign_id + "?agency_id=" + agency_id;
      return fetch(fetchUrl, updateHeaders()).then((response) => response.blob())
          .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
            const _url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = _url;
            link.setAttribute(
                'download',
                `export_campagna_${campaign_name}.csv`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            //window.open(_url, "_blank").focus(); // window.open + focus
          }).catch((err) => {
            console.log(err);
          });
    };

    downloadAtomic = (campaign_id, campaign_name,agency_id) => {
      const baseUrl = process.env.REACT_APP_BASE_URL;
      const fetchUrl = baseUrl + "/api/campaigns/atomic_csv/" + campaign_id + "?agency_id=" + agency_id;
      return fetch(fetchUrl, updateHeaders()).then((response) => response.blob())
          .then((blob) => { // RETRIEVE THE BLOB AND CREATE LOCAL URL
            const _url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = _url;
            link.setAttribute(
                'download',
                `dato_atomico_campagna_${campaign_name}.csv`,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            link.parentNode.removeChild(link);
            //window.open(_url, "_blank").focus(); // window.open + focus
          }).catch((err) => {
            console.log(err);
          });
    };
   
}


// eslint-disable-next-line import/no-anonymous-default-export
export default new CampaignService();