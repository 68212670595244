import React, { useState, useEffect} from "react";
import { useForm, Controller, useWatch } from "react-hook-form";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  // Checkbox,
  InputLabel,
  // ListItemIcon,
  // ListItemText,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Button,
  Box,
  Chip,
  // Typography,
  ListItemIcon,
  Checkbox,
  ListItemText,
} from "@mui/material";
import ForwardIcon from '@mui/icons-material/Forward';
import "../Style/FormStyle.css";
import "animate.css";
import CampaignService from "../../services/campaign.services";
import { format } from "date-fns";
import {it} from 'date-fns/locale'
import { useNavigate } from "react-router-dom";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 10;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 7.5 + ITEM_PADDING_TOP,
      width: 250,
      borderRadius: "8px",
    },
  },
};

function FormCampaignCustom() {
    const [projects, setProjects] = useState([]); 
    const [feeds, setFeeds] = useState([]);
    const [versions, setVersions] = useState([]);
    const [trips, setTrips] = useState([]);
    const [stroke, setStroke] = useState([]);
    const [filteredStrokes, setFilteredStrokes] = useState([]);
    const [isTextFieldFilled, setIsTextFieldFilled] = useState(false);
    const [isDateFilled, setIsDateFilled] = useState(false);
    const [schemas, setSchemas] = useState([]);
  
    const navigate = useNavigate();

    const getListProjects = () => {
        CampaignService.getProjects().then((data) => {
          setProjects(data);
        });
      };
    
      const getListFeeds = () => {
        CampaignService.getFeeds().then((data) => {
          setFeeds(data);
        });
      };

      const getVersions = () => {
        CampaignService.getVersions().then((data) => {
          setVersions(data);
        });
      };

      const getListTrips = (version) => {
        CampaignService.getTrips(version).then((data) => {
          setTrips(data);
        });
      };

      const getListStokes = (version) => {
        CampaignService.getStroke(version).then((data) => {
          setStroke(data);
        })
      }
    
      const {
        control,
        handleSubmit,
        formState: { isValid },
        setError,
        clearErrors,
        setValue,
        getValues
      } = useForm({ defaultValues: {
        name: "",
        start_date: null,
        end_date: null,
        select_project: [],
        select_feed: [],
        select_version: [],
        select_trips: [],
        select_stroke: []
    } });

    const [startDate, endDate, selected_project, selected_feed, selected_version, selected_trips, selected_stroke] = useWatch({
        control,
        name: ["start_date", "end_date", "select_project", "select_feed", "select_version", "select_trips", "select_stroke"],
      });

      useEffect(() => {
        getListProjects();
      }, []);
    
      useEffect(() => {
        if (selected_project) {
          getListFeeds();
        }
      }, [selected_project]);

      useEffect(() => {
        if (selected_feed) {
          getVersions();
        }
      }, [selected_feed]); 

      useEffect(() => {
        if (selected_version.length > 0) {
          getListTrips(schemas[0]?.schema);
        }
      }, [selected_version, schemas]); // eslint-disable-line react-hooks/exhaustive-deps
      
      useEffect(() => {
        if (selected_version.length > 0 ) {
          getListStokes(schemas[0]?.schema)
        }
          console.log("🚀 ~ useEffect ~ schemas[0]?.schema:", schemas[0]?.schema)
      }, [selected_trips, schemas]); // eslint-disable-line react-hooks/exhaustive-deps


      useEffect(() => {
        const updatedFilteredStrokes = stroke.filter(strokeItem => 
          selected_trips.includes(strokeItem.route.route_id)
        );
        setFilteredStrokes(updatedFilteredStrokes);
      
        // ottiengo il valore attuale di select_stroke dal form
        const currentStrokeValues = getValues('select_stroke');
        
        // filtro gli stroke che non appartengono più alle trips selezionate
        const newStrokeValues = currentStrokeValues.filter(strokeId => 
          updatedFilteredStrokes.some(strokeItem => strokeItem.trip_id === strokeId)
        );
      
        // aggiorno il valore di select_stroke nel form se è cambiato
        if (newStrokeValues.length !== currentStrokeValues.length || 
            !newStrokeValues.every(val => currentStrokeValues.includes(val))) {
          setValue('select_stroke', newStrokeValues);
        }
      }, [selected_trips, stroke, setValue, getValues]);
      

      useEffect(() => {
        if (startDate !== null && endDate !== null) {
          setIsDateFilled(true);
        } else {
          setIsDateFilled(false);
        }
      }, [startDate, endDate]);

      const onSubmit = (data) => {
        const { name, start_date, end_date } = data;
        const feedVersions = schemas;
        const startDate = start_date ? format(start_date,"yyyy-MM-dd") : null;
        const endDate = end_date ? format(end_date,"yyyy-MM-dd") : null;
        const newCampaign = {
          name,
          start_date: startDate,
          end_date: endDate,
          feedVersions,
        };
        console.log('new custom campaign', newCampaign)

        navigate('/configuraCampagnaCustom/'+feedVersions[0].schema,{
          state:{
                name: name,
                start_date:startDate,
                end_date:endDate,
                project:feedVersions[0].project,
                feed:feedVersions[0].feed,
                version:feedVersions[0].version,
                tripsIds: selected_stroke
              }
          }
        )
      };  
    
  
    return ( 
        <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "grid",
          alignItems: "baseline",
          gridRowGap: "20px",
        }}
      >

        <Controller
          name="name"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              sx={{ display: "grid" }}
              id="name_campaign"
              inputProps={{ maxLength: 50 }}
              label="Nome campagna"
              variant="standard"
              value={value}
              onChange={(e) => {
                onChange(e);
                setIsTextFieldFilled(e.target.value !== "");
              }}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
          rules={{ required: "Inserisci un nome" }}
        />

{isTextFieldFilled ? (
          <>
            <Controller
              name="start_date"
              rules={{ required: "Data obbligatoria" }}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                  <DatePicker
                    label="Data Inizio"
                    inputFormat="dd/MM/yyyy"
                    //disablePast={true}
                    value={value}
                    onChange={(newValue) => {
                      // if (newValue > endDate) {
                      //   setError("end", {
                      //     type: "custom",
                      //     message: "Data non corretta",
                      //   });
                      // } else {
                      //   clearErrors("end");
                      // }
                      onChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        sx={{ display: "grid" }}
                        {...params}
                        inputProps={{ ...params.inputProps, readOnly: true }} // evitare l'inserimento dattiloscritto della data
                        id="start_date"
                        variant="standard"
                        error={!!error}
                        helperText={error ? error.message : null}
                      />
                    )}
                  />
                </LocalizationProvider>
              )
            }
            />
            <Controller
              name="end_date"
              rules={{ required: "Data obbligatoria", deps: ["start_date"] }}
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={it}>
                  <DatePicker
                    minDate={startDate}
                    label="Data Fine"
                    //disablePast={true}
                    sx={{ display: "none" }}
                    inputFormat="dd/MM/yyyy"
                    value={value}
                    onChange={(newValue) => {
                      // if (newValue >= startDate) {
                      //   clearErrors("end");
                      // } else {
                      //   setError("end", {
                      //     type: "custom",
                      //     message: "Data non corretta",
                      //   });
                      // }
                      onChange(newValue);
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          sx={{ display: "grid" }}
                          {...params}
                          inputProps={{ ...params.inputProps, readOnly: true }} // evitare l'inserimento dattiloscritto della data
                          id="end_date"
                          variant="standard"
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      );
                    }}
                  />
                </LocalizationProvider>
              )}
            />
          </>
        ) : null}

{isDateFilled ? (
            <Controller
              name="select_project"
              control={control}
              rules={{ required: "Selezione obbligatoria" }}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ display: "grid" }}>
                  <InputLabel
                    id="select-project"
                    sx={{ display: "grid" }}
                  >
                    Seleziona Progetto
                  </InputLabel>
                  <Select
                    labelId="select-project"
                    variant="standard"
                    defaultValue=''
                    value={value || ''}
                    onChange={(event) => {
                      const project_S = event.target.value;

                      // Aggiorna l'elenco di feed filtrati in base al progetto selezionato
                      /*let filtered_feeds = feeds
                        .filter((feed) => feed.projectId.name === project_S)
                        .map((feed) => feed.name);
                      */
                      setValue('select_feed', []);
                      setValue('select_trips', []);
                      setValue('select_stroke', []);
                      return onChange(project_S);
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        <Chip label={selected} />
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled value=''>
                        <em>Progetti</em>
                      </MenuItem>
                    {projects.map((option, index) => (
                      <MenuItem key={index} value={option.name}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            />
        ): null }

{selected_project.length > 0 ? (
            <Controller
              name="select_feed"
              control={control}
              rules={{ required: "Selezione obbligatoria" }}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ display: "grid" }}>
                  <InputLabel id="select-feed" sx={{ display: "grid" }}>
                    Seleziona Feed
                  </InputLabel>
                  <Select
                    labelId="select-feed"
                    variant="standard"
                    defaultValue=''
                    value={value || ''}
                    onChange={(event) => {
                      const feed_S = event.target.value;
                      setValue('select_version', []);
                      setValue('select_stroke', []);
                      return onChange(feed_S);
                    }}
                    renderValue={(selected) => {
                      return (
                          <Box sx={{display: "flex", flexWrap: "wrap", gap: 0.5}}>
                            <Chip label={selected} />
                          </Box>
                      )
                    }}
                    MenuProps={MenuProps}
                    >
                    <MenuItem disabled value=''>
                      <em>Feed</em>
                    </MenuItem>
                    {feeds
                      .filter((feed) => selected_project.includes(feed.projectId.name))
                      .map((option, index) => (
                        <MenuItem key={index} value={option.name}>
                        {option.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
        ) : null}

{selected_feed.length > 0 ? (
            <Controller
              name="select_version"
              control={control}
              rules={{ required: "Selezione obbligatoria" }}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ display: "grid" }}>
                  <InputLabel
                    id="mutiple-select-version"
                    sx={{ display: "grid" }}
                  >
                    Seleziona Versione
                  </InputLabel>
                  <Select
                    labelId="mutiple-select-version"
                    variant="standard"
                    defaultValue=''
                    value={value || ''}
                    onChange={(event) => {
                      const version_S = event.target.value;
                      const schema_S = versions.find((v) => v._id === version_S);
                      setSchemas([{
                        version: schema_S.name, 
                        schema: schema_S.namespace, 
                        feed: schema_S.feedSourceId?.name, 
                        project: schema_S.feedSourceId.projectId?.name
                      }]);
                      setValue('select_trips', []);
                      return onChange(version_S);
                    }}
                    renderValue={(selected) => {
                      let val = versions.find(v => v._id === selected);
                      return (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          <Chip label={val.name} />
                        </Box>
                      )
                    }}
                    MenuProps={MenuProps}
                  >
                    <MenuItem disabled value=''>
                      <em>Versioni</em>
                    </MenuItem>

                    {versions
                      .filter((version) => selected_feed.includes(version.feedSourceId?.name))
                      .map((option,index) => (
                        <MenuItem key={index} value={option._id}>
                          <ListItemText
                              primary={option.name}
                              secondary={option.feedSourceId?.name}
                            />
                        </MenuItem>
                      )
                      )}
                  </Select>
                </FormControl>
              )}
            />
        ) : null}

{selected_version.length > 0 ? (
            <Controller
              name="select_trips"
              control={control}
              rules={{ required: "Selezione obbligatoria" }}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ display: "grid" }}>
                  <InputLabel id="select-feed" sx={{ display: "grid" }}>
                    Seleziona Linea/e
                  </InputLabel>
                  <Select
                    labelId="select-trip"
                    variant="standard"
                    multiple
                    defaultValue=''
                    value={value || []}
                    onChange={(event) => {
                      let trip_S;
                        if (event.target.value.includes('_all')) {
                        // seleziono o deseleziono tutte le linee
                        trip_S = event.target.value.includes('_all') && value.length !== trips.length
                          ? trips.map((trip) => trip.route_id)
                          : [];
                      } else {
                        trip_S = event.target.value;
                      }
                      console.log('Selected,trips: ',trip_S);
                      setValue('select_trips', trip_S)
                      return onChange(trip_S);
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    >
                    <MenuItem disabled value=''>
                      <em>Linee</em>
                    </MenuItem>
                    {/* <MenuItem value="_all">
                      <ListItemIcon>
                        <Checkbox
                          value="_all"
                          checked={value.length === trips.length}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Seleziona tutte" />
                    </MenuItem> */}
                    {trips
                      .slice()
                      .sort((a, b) => a.route_long_name.localeCompare(b.route_long_name))
                      .map((option, index) => (
                        <MenuItem key={index} value={option.route_id}>
                          <ListItemIcon>
                            <Checkbox
                              value={option.route_id}
                              checked={value.includes(option.route_id)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={option.route_id}
                            secondary={option.route_long_name}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
        ) : null}

{selected_trips.length > 0 ? (
            <Controller
              name="select_stroke"
              control={control}
              rules={{ required: "Selezione obbligatoria" }}
              render={({ field: { onChange, value } }) => (
                <FormControl sx={{ display: "grid" }}>
                  <InputLabel id="select-feed" sx={{ display: "grid" }}>
                    Seleziona Corsa/e
                  </InputLabel>
                  <Select
                    labelId="select-stroke"
                    variant="standard"
                    multiple
                    defaultValue=''
                    value={value || []}
                    onChange={(event) => {
                      let stroke_S;
                        if (event.target.value.includes('_all')) {
                        // seleziono o deseleziono tutte le corse
                        stroke_S = event.target.value.includes('_all') && value.length !== filteredStrokes.length 
                        ? filteredStrokes.map(strokeItem => strokeItem.trip_id) 
                        : [];
                          
                      } else {
                        stroke_S = event.target.value;
                      }
                      console.log('Selected,stroke: ',stroke_S);
                      setValue('select_stroke', stroke_S)
                      return onChange(stroke_S);
                    }}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.slice(0, 8).map((tripId) => {
                          const stroke = filteredStrokes.find(stroke => stroke.trip_id === tripId);
                          const label = stroke.trip_short_name;
                          return (
                            <Chip key={tripId} label={label} />
                          );
                        })}
                        {selected.length > 8 && (
                          <Chip label={`+${selected.length - 8} altri`} />
                        )}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                    >
                    <MenuItem disabled value=''>
                      <em>Corse</em>
                    </MenuItem>
                    <MenuItem value="_all">
                      <ListItemIcon>
                        <Checkbox
                          value="_all"
                          checked={value.length === filteredStrokes.length}
                        />
                      </ListItemIcon>
                      <ListItemText primary="Seleziona tutte" />
                    </MenuItem>
                    {filteredStrokes
                      .filter(strokeItem => selected_trips.includes(strokeItem.route.route_id))
                      .map((option, index) => (
                        <MenuItem key={index} value={option.trip_id}>
                          <ListItemIcon>
                            <Checkbox
                              checked={value.includes(option.trip_id)}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={option.trip_short_name}
                            secondary={'Linea: ' + option.route.route_id + ' - ' + option.route.route_long_name}
                          />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              )}
            />
) : null}

<br />

        <Button
          sx={{width:250, m:'auto', whiteSpace: 'nowrap'}}
          variant="contained"
          type="submit"
          endIcon={<ForwardIcon/>}
          disabled={!isValid || startDate > endDate || startDate === endDate}
        >
          Prosegui con la definizione
        </Button>

</form>
</>

     );
}

export default FormCampaignCustom;