import updateHeaders from "../utils"

class UsersService {

    getCompanies = (agency_id) => {
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const fetchUrl = baseUrl + "/api/companies/?agency_id=" + agency_id;
        return fetch(fetchUrl, updateHeaders()).then((res) => 
            res.json()).then((json) =>
              {return json});
      };

}
// eslint-disable-next-line import/no-anonymous-default-export
export default new UsersService();