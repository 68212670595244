import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Image, View, useAuthenticator } from '@aws-amplify/ui-react';
import LogoRegioneAMP_color from "../../Img/LogoRegioneAMP_color.png";

const RedirectPage = () => {
  const navigate = useNavigate();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [timeLeft, setTimeLeft] = useState(20);

  useEffect(() => {
    const timer = timeLeft > 0 && setInterval(() => setTimeLeft(timeLeft - 1), 1000);

    if (timeLeft === 0) {
      signOut();
      navigate('/');
    }

    return () => clearInterval(timer);
  }, [timeLeft, navigate, signOut]);

  return (
    <div style={{ padding: 20, textAlign: 'center' }}>
        <View textAlign="center" >
            <Image alt="RegioneAMP logo" src={LogoRegioneAMP_color} />
        </View>
      <Typography variant="h6" sx={{mt: 3}}>
        Sessione scaduta, stai per essere reindirizzato alla pagina di login tra {timeLeft} secondi...
      </Typography>
    </div>
  );
};

export default RedirectPage;

