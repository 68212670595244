import React from "react";
import { Box, Button, Typography } from "@mui/material";
// import { aziende } from "../../dati/tpl";
// import FormDate from "../UI/FormDate";
import FormCampaign from "../UI/FormCampaign";
// import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import "../Style/FormStyle.css";
import UndoIcon from '@mui/icons-material/Undo';



export default function NewCampaign() {
  const navigate = useNavigate();

// const handleCustomCampaign = () => {
//   navigate('/creaCampagnaCustom');
// }
  // useEffect(() => {
  //   Swal.fire({
  //     title: "ATTENZIONE",
  //     html: "Si ricorda che per la creazione di una campagna personalizzata è possibile selezionare solamente un FEED.",
  //     icon: "info",
  //     toast: true,
  //     confirmButtonColor: "#3085d6",
  //     confirmButtonText: "Ok, capito!",
  //     position: "top-end",
  //     timerProgressBar: true,
  //     timer: 90000,
  //     customClass: {
  //       container : "swal-custom-container"
  //     },
  //   });
  // });

  return (
    <Box>
      <Typography
        color="text.secondary"
        gutterBottom
        component={"span"}
        align="center"
      >
        <h2> Nuova Campagna Rilevazione</h2>
        <h3>Inserire i dati per inizializzare una nuova campagna</h3>
      </Typography>
        <Button variant="outlined" startIcon={< UndoIcon/>} onClick={() => navigate(-1)} >
            Torna alla scelta campagna
        </Button>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(1,40em)",
          gridColumnGap: "2px",
          justifyContent: "center",
          mt: 3
        }}
      >
        {/* <FormDate /> */}
        <br/>
        <FormCampaign />

      </Box>
    </Box>
  );
}
