import React from "react";
import CardMaterial from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

const Card = (props) => {
  return (
    <CardMaterial
      sx={{
        minWidth: 'auto',
        height: 'auto',
        marginLeft: "1%",
        position: "inherit",
        marginTop: -10,
      }}
    >
      <CardContent>{props.children}</CardContent>
    </CardMaterial>
  );
};
export default Card;
