import React from "react";
import { Box, Button, Typography } from "@mui/material";
import FormCampaignCustom from "../UI/FormCampaignCustom";
import UndoIcon from '@mui/icons-material/Undo';
import { useNavigate } from "react-router-dom";


function NewCampaignCustom() {

  const navigate = useNavigate();

  return (
    <Box>
      <Typography
        color="text.secondary"
        gutterBottom
        component={"span"}
        align="center"
      >
        <h2> Nuova Campagna Rilevazione Custom</h2>
        <h3>Inserire i dati per inizializzare una nuova campagna personalizzata</h3>
      </Typography>

      <Button variant="outlined" startIcon={< UndoIcon/>} onClick={() => navigate(-1)} >
            Torna alla scelta campagna
      </Button>

      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(1,40em)",
          gridColumnGap: "2px",
          justifyContent: "center",
          mt: 3
        }}
      >
        <FormCampaignCustom />
      </Box>
    </Box>
  );
}

export default NewCampaignCustom;
