import { styled } from "@mui/material/styles";


const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    fontSize: 15,
    color: "rgb(112, 112, 112)"
  }));

  export default DrawerHeader;