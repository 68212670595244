import React, {useEffect, useState} from "react";
import { Box, Stack, Typography, Button } from "@mui/material";
import CreateUserForm from "../UI/FormUser.js";
import Lista from "../UI/ListUsers.js";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import {useAuthenticator} from "@aws-amplify/ui-react";

function UsersManagemant() {
  const [viewForm, setViewForm] = useState(false);
  const [refreshNeeded, setRefreshNeeded] = useState(false);
  const [canCreateUser, setCanCreateUser] = useState(true)
  const { user } = useAuthenticator((context) => [context.user])

  useEffect(() => {
    if (user?.getSignInUserSession()?.getAccessToken()?.payload["cognito:groups"].includes('adminReadOnly')) {
      setCanCreateUser(false)
    }
  }, [user])


  const refresh = () => {
    // console.log('refresh')
    setRefreshNeeded(true);
  }

  const handleViewForm = () => {
    setViewForm(!viewForm);
  };
  return (
    <>
      <Box>
        <Typography
          color="text.secondary"
          gutterBottom
          component={"span"}
          align="center"
        >
          <h2> Creazione e dettaglio utenze</h2>
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          >
          {viewForm && <CreateUserForm onSubmit={refresh} />}
          {canCreateUser &&(
            viewForm ? (
                <Button 
                    type="submit" 
                    variant="outlined"
                    color="error"
                    startIcon={<CloseIcon />}
                    onClick={handleViewForm} 
                    sx={{ mt:1.5,ml: 2}}
                >
                   Annulla registrazione
                </Button>
            ) : (
                <Button 
                    type="submit" 
                    variant="outlined"
                    startIcon={<AddIcon />}
                    onClick={handleViewForm}
                    sx={{mt: 3.2}}
                    >
                   Registra nuova utenza
                </Button>
            ))
          }
        </Stack>
        <Box sx={{ mt: 5 }}>
          <Lista  triggerRefresh={refreshNeeded}/>
        </Box>
      </Box>
    </>
  );
}

export default UsersManagemant;
