import React from "react";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
// import { aziende } from "../../dati/tpl";
// import FormDate from "../UI/FormDate";
// import FormCampaign from "../UI/FormCampaign";
// import Swal from "sweetalert2";
// import "../Style/FormStyle.css";


export default function NewCampaign() {
  const navigate = useNavigate();

const handleOrdinaryCampaign = () => {
  navigate('/creaCampagnaOrdinaria');
}

const handleCustomCampaign = () => {
  navigate('/creaCampagnaCustom');
}


  return (

<Box>
  <Typography
    color="text.secondary"
    gutterBottom
    component={"span"}
    align="center"
  >
    <h3>Seleziona il tipo di campagna che vuoi creare</h3>
  </Typography>

  <Grid container spacing={2} sx={{ mt: 6, mb: 10, justifyContent: "center" }}>
    <Grid item xs={12} sm={5}>
      <Button
        onClick={handleOrdinaryCampaign}
        variant="contained"
        fullWidth
        sx={{
          p: 2,
          fontSize: '1.1rem',
          fontWeight: 'bold',
          color: '#3B3B3B'
        }}
      >
        Crea campagna ordinaria
      </Button>
    </Grid>

    <Grid item xs={12} sm={2} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Divider orientation="vertical" flexItem sx={{ display: { xs: "none", sm: "flex" } }} />
    </Grid>

    <Grid item xs={12} sm={5} sx={{ textAlign: "center" }}>
      <Button
        onClick={handleCustomCampaign}
        variant="contained"
        fullWidth
        sx={{
          p: 2,
          fontSize: '1.1rem',
          fontWeight: 'bold',
          color: '#535353',
          backgroundColor: "#F9F871",
          '&:hover': {
            backgroundColor: "#E0D95A"
          }
        }}
      >
        <Box>
          Crea campagna custom<br />
          {/* <span style={{ fontSize: '0.95rem', textTransform: 'none', fontStyle: 'italic' }}>
            (Nota: può essere selezionato un solo feed)
          </span> */}
        </Box>
      </Button>
      <Typography
        sx={{ fontSize: '0.95rem', fontStyle: 'italic', mt: 1 }}
      >
        (Nota: può essere selezionato un solo feed)
      </Typography>
    </Grid>
  </Grid>
</Box>
  );
}
