import React, { useState, useEffect } from "react";
import { withAuthenticator } from "@aws-amplify/ui-react";
import StripedDataGrid from "../Style/StripedDataGrid";
import "../Style/List.css";
import {
  itIT,
  // GridActionsCellItem,
  // GridToolbar,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector, GridActionsCellItem,
} from "@mui/x-data-grid";
import { useAuthenticator } from "@aws-amplify/ui-react";
import UsersService from "../../services/users.services";
import CognitoService from "../../services/cognito.services";
import {Fade, Tooltip} from "@mui/material";
import {DeleteForever} from "@mui/icons-material";
import Swal from "sweetalert2";

function Lista(props) {
  const { user } = useAuthenticator((context) => [context.user]);
  const [data, setData] = useState(null);
  const [companies, setCompanies] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(15);
  const [visible, setVisible] = useState(false)
  let agency_id = user.attributes["custom:tpl_id"];

  async function fetchUsers() {
    const admins = (await CognitoService.listUsersInGroup("admin")).map(
        (user) => user.Username
    );
    const adminsCompany = (
        await CognitoService.listUsersInGroup("adminCompany")
    ).map((user) => user.Username);
    const adminsReadOnly = (
        await CognitoService.listUsersInGroup("adminReadOnly")
    ).map((user) => user.Username);
    const editorUsers = (
        await CognitoService.listUsersInGroup("editorUser")
    ).map((user) => user.Username);
    CognitoService.listAllUsers().then((res) => {
      // console.log(res)
      res.forEach((user) => {
        if (editorUsers.includes(user.Username)) {
          if ("roles" in user) user.roles.push("editor");
          else user.roles = ["EditorUser"];
        }
        if (adminsReadOnly.includes(user.Username)) {
          if ("roles" in user) user.roles.push("admin sola lettura");
          else user.roles = ["AdminSolaLettura"];
        }
        if (adminsCompany.includes(user.Username)) {
          if ("roles" in user) user.roles.push("admin di azienda");
          else user.roles = ["AdminAzienda"];
        }
        if (admins.includes(user.Username)) {
          if (!("roles" in user))
            user.roles = ["SuperAdmin"];
        }
      });
      if(agency_id === '0'){
        setData(res)
      }
      // else{
      // setData(res.filter((user) => {
      //   let agencies = user.Attributes.find((att) => att.Name === 'custom:tpl_id').Value.split(';')
      //   for(let agency of agency_id.split(';')){
      //     if (agencies.includes(agency))
      //       return true
      //   }
      // }))
      // }
      else {
        setData(res.filter((user) => {
          let agencies = user.Attributes.find((att) => att.Name === 'custom:tpl_id').Value.split(';');
          return agency_id.split(';').some(agency => agencies.includes(agency));
        }));
      }
      setIsLoading(false);
    });
    await allCompanies(agency_id);
  }

  useEffect(() => {
    const roles = user?.getSignInUserSession()?.getAccessToken()
        ?.payload["cognito:groups"]
    //console.log('prova', roles.length === 1, roles[0] === 'admin')
    setVisible(roles.length === 1 && roles[0] === 'admin')
  }, [user])

  useEffect(() => {
    fetchUsers();
  }, [props.triggerRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const allCompanies = (agency_id) => {
    UsersService.getCompanies(agency_id).then((data) => {
      let companiesMap = {};
      data.forEach((company) => {
        companiesMap[company.agency_id] = company.agency_name;
      });
      setCompanies(companiesMap);
      // console.log(companiesMap);
    });
  };

  const disableUser = (userName) => {
    Swal.fire({
      title: `Sei sicuro di eliminare l'utente ${userName} ?`,
      showDenyButton: true,
      toast: true,
      confirmButtonColor: "#3085d6",
      confirmButtonText: 'Conferma',
      denyButtonText: `Annulla`,
    }).then((result) => {
      if (result.isConfirmed)
        CognitoService.deleteUser(userName).then((res) => {
          Swal.fire({
            title: 'Utente correttamente eliminato',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok'
          })
          fetchUsers()
        })
            .catch((err) => Swal.fire({
              title: "Errore nell'eliminazione dell'utente",
              icon: 'error',
              confirmButtonColor: '#3085d6',
              confirmButtonText: 'Ok'
            }))
  })}
  
  const columns = [
    { field: "Email", headerName: "Email", flex:1 },
    { field: "Profile", headerName: "Ruolo", flex:1 },
    { field: "Agency Name", headerName: "Azienda", flex:1 },
  ];

  if (visible) {
    columns.push({
      field: "actions",
      type: "actions",
      filterable: false,
      flex: 0.5,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            key={id}
            label="Visualizza dettagli"
            onClick={() => disableUser(id)}
            color="inherit"
            icon={
              <Tooltip
                title={
                  <span style={{ margin: 1, fontSize: 13 }}>
                    Elimina utenza
                  </span>
                }
                transitioncomponent={Fade}
                transitionprops={{ timeout: 800 }}
                followcursor="true"
                sx={{ fontSize: 20 }}
              >
                <DeleteForever />
              </Tooltip>
            }
          />,
        ];
      },
    });
  }

  const rows =
    data?.map((user) => {
      let tplIds = user.Attributes.find(
        (attribute) => attribute.Name === "custom:tpl_id"
      ).Value.split(";");
      let agencyNames = tplIds.map((tplId) => companies[tplId] || "");

      return {
        Email: user.Attributes.find((attribute) => attribute.Name === "email")
          .Value,
        Profile: user.roles.join() || "nessun ruolo",
        "Agency Name": agencyNames.join(";\r"),
      };
    }) || [];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
      </GridToolbarContainer>
    );
  }

  return (
      <div style={{ height: '70vh', width: "100%" }}>
        <StripedDataGrid
          pageSize={pageSize}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          rowsPerPageOptions={[15, 25, 35, 45]}
          pagination
          rows={rows}
          columns={columns}
          loading={isLoading}
          localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
          visibleFooterSelectedRowCount
          getEstimatedRowHeight={() => 100}
          getRowHeight={() => "auto"}
          getRowId={(row) => row.Email}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? "even" : "odd"
          }
          slots={{ toolbar: CustomToolbar }}
          sx={{
            "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
              py: 1,
            },
            "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
              py: 2,
            },
            "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
              py: 3,
            },
            " '&.MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
          }}
        />
      </div>
  );
}

export default withAuthenticator(Lista, true);
